@import 'core/styles/refrences/index.scss';

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;

@mixin background-gradient {
  background-image: linear-gradient(
                  90deg,
                  $base-color 0px,
                  $shine-color 40px,
                  $base-color 80px
  );
  background-size: 600px;
}

.pmx-last-24 {
  display: flex;
  width: 75%;
  gap: 16px;
  margin-inline: auto;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin-top: 120px;

  @include pmx-pre-lg {
    width: 90%;
    flex-direction: column;
    margin-top: 40px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  p {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #777e90;

    @include pmx-pre-lg {
      display: none;
    }
  }


  .cards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    .stats-card {
      width: 50%;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid #f0f0f0;
      padding: 24px;

      @include pmx-pre-lg {
        padding: 8px;
        width: calc(100% - 16px);
        flex-direction: row;
        gap: 16px;
      }


      .header {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 16px;

        h4 {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include pmx-pre-lg {
            width: 100%;
            font-size: 16px;
            margin-top: unset;
          }

          svg {
            cursor: pointer;
          }
        }

        p {
          margin-top: 16px;
          font-size: 16px;
          font-weight: 500;
          color: #777e90;

          @include pmx-pre-lg {
            display: none;
          }
        }


      }

      .divider {
        display: inline-flex;
        width: 100%;
        height: 1px;
        border-top: 1px solid $border;
        margin-top: 24px;
      }

      .currency {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 24px;

        .currency-name {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            width: 32px;
            height: 32px;
          }

          .col {
            display: flex;
            align-items: center;
            justify-content: start;
            text-align: right;
            column-gap: 16px;

            span:last-of-type {
              color: #b3b3b3;
              font-size: 10px;
              font-weight: 500;
            }
          }
        }

        .changes {
          display: flex;
          justify-content: center;
          max-width: 40%;
        }

        .bullish {
          color: #36b274;
        }

        .bearish {
          color: #b23636;
        }
      }

      .skeleton-part {
        flex: 1;
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        border-radius: 8px;
      }
    }
  }
}

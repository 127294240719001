@import "core/styles/refrences/index";

.pmx-order-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background: $transaction-bg;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 0;
  border: 1px solid $border;
  //height: 195px;

  .ant-divider {
    margin: unset;
    margin-top: 16px;
  }

  .head {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;

    .tracking-code {
      display: none !important;
      flex-direction: column;
      align-items: center;

      .text-overflow {
        width: 100px;
        // overflow-x: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
        direction: ltr;
      }

      > span {
        font-size: 12px;
        color: $gray-span;
        white-space: nowrap;
      }

      p {
        font-size: 10px;
        font-weight: 500;
        white-space: nowrap;
        gap: 2px;
        width: 100%;
        text-align: right;

        img {
          width: 12px;
          height: 12px;
        }

        span {
          font-size: 8px;
        }
      }
    }


    .pair {
      display: flex;

      .title {
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          color: $gray-span;
        }

        p {
          font-size: 12px;
        }
      }

      .icons {
        display: flex;

        img {
          background: $transaction-bg;
          border-radius: 50px;
          padding: 3px;
          box-sizing: border-box;
          width: 27px;
          height: 27px;
        }

        img:last-child {
          margin-right: -16px;
        }
      }
    }
  }

  > .divider {
    display: inline-block;
    width: 100%;
    height: 1px;
    border: 1px dashed $blue;
    margin-top: 16px;
  }

  .data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 0 16px;
    margin-top: 16px;
    box-sizing: border-box;

    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      > div {
        display: flex;
        flex-direction: column;

        .text-overflow {
          width: 100px;
          // overflow-x: hidden;
          // text-overflow: ellipsis;
          white-space: nowrap;
          direction: ltr;
        }

        > span {
          font-size: 14px;
          color: $gray-span;
          white-space: nowrap;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
          gap: 2px;
          width: 100%;
          text-align: right;

          img {
            width: 12px;
            height: 12px;
          }

          span {
            font-size: 10px;
          }
        }
      }

      > div:last-child {
        margin-top: auto;
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-order-item {
    max-width: 302px;
    padding-top: 12px;
    margin: auto;
    width: 100%;

    .head {
      .pair {
        .title {
          span {
            font-size: 12px;
          }

          p {
            font-size: 10px;
          }
        }

        .icons {
          img {
            width: 20px;
            height: 20px;
          }

          img:last-child {
            margin-right: -10px;
          }
        }
      }

      .tracking-code {
        @include pmx-pre-lg {
          display: flex !important;
        }
      }
    }

    .dashed-divider {
      margin-top: 12px;
    }

    .data {
      padding: 0 12px;
      margin-top: 12px;

      .col {
        > div {
          > p,
          > span {
            font-size: 12px;
          }
        }

        > div:last-child {
          margin-top: 12px;
        }


      }

      .col:nth-child(2) {
        > div:last-child {
          margin-top: 0px;
        }
      }
    }
  }
}

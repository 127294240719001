@import "core/styles/refrences/index";

.pmx-invite-friends {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-inline: auto;
  margin-top: 80px;
  position: relative;

  @include pmx-pre-lg {
    display: none;
  }

  .overlay {
    display: block;
    position: absolute;
    opacity: 60%;
    top: -492px;
    left: 0;
    width: 100%;
    height: 1350px;
    background: url('../../../../../assets/images/invite/invite-friends-union.png') no-repeat center;
    @include pmx-pre-lg {
      display: none;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 40px;
      font-weight: 700;

      &:nth-child(2) {
        margin-top: 8px;
      }
    }
  }

  .features {
    margin-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 310px;


    .pmx-button {
      width: 160px;
      height: 48px;

      button {
        background-color: #0C68F4;
      }
    }

    .feature-item {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;

      img {
        border-radius: 50px;
        width: 80px;
        height: 80px;
      }

      button {
        background: linear-gradient(0deg, #E3EAFA -18.75%, rgba(255, 255, 255, 0) 100%);;
        border: 0;
        border-radius: 16px;
        outline: none;
        padding: 16px 12px;
        height: 48px;
        display: flex;
        align-items: center;

        > span:last-child, > div span:last-child, > div > div, span:last-child > div p {
          font-size: 14px;
          font-weight: 700;
          margin-right: 8px;
        }

        &.stats {
          flex-direction: column;
          height: auto;
          gap: 16px;

          > div span:first-child, > div p {
            font-size: 12px;
          }

          > div div span:first-child {
            color: #777E90;
          }

          > div > div {
            display: flex;
            gap: 8px;
          }
        }

      }
    }

    .feature-item:nth-child(2) {
      top: 0px;
      left: 0;
    }

    .feature-item:nth-child(3) {
      top: 98px;
      left: 192px;
    }

    .feature-item:nth-child(4) {
      top: 117px;
      left: calc(50% - 80px);
    }

    .feature-item:nth-child(5) {
      top: 98px;
      right: 192px;
    }

    .feature-item:last-child {
      top: 0;
      right: 0;
    }

  }

  .extra-profit-box {
    position: relative;
    background: $lightGreyBorder;
    display: flex;
    padding: 24px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    height: 240px;
    margin-top: 260px;

    .gradient-overlay {
      display: block;
      background: linear-gradient(90deg, #5A8BFF 0%, #F0F0F0 47.5%);
      position: absolute;
      top: 24px;
      left: 24px;
      width: calc(100% - 24px);
      height: 100%;
      max-height: 192px;
      border-radius: 8px;
    }

    .text-content {
      max-width: 60%;
      z-index: 100;
      gap: 16px;
      display: flex;
      flex-direction: column;


      h3 {
        font-size: 32px;
        font-weight: 700;
      }

      p {
        font-weight: 700;
        font-size: 14px;
      }

    }

    img {
      z-index: 100;
      width: 213px;
      height: 254px;
      margin-top: -51px;
      margin-left: 56px;
    }
  }
}

.pmx-invite-friends-mobile {

  @include pmx-lg {
    display: none;
  }

  display: flex;
  flex-direction: column;
  width: 90%;
  margin-inline: auto;
  position: relative;
  min-height: 400px;

  .upper {
    background: url('../../../../../assets/images/invite/invite-friends-union.png') no-repeat center;
    background-size: contain;
    position: relative;
    min-height: 400px;
    width: 100%;

    .title {
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }

    .pmx-button {
      position: absolute;
      width: 160px;
      height: 48px;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);

      button {
        background-color: #0C68F4;
      }
    }

    .swiper {
      display: flex;
      width: 100%;
      margin-top: 48px;
      position: absolute;
      bottom: 0;

      .feature-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;

        img {
          border-radius: 50px;
          width: 80px;
          height: 80px;
        }

        button {
          background: linear-gradient(0deg, #E3EAFA -18.75%, rgba(255, 255, 255, 0) 100%);;
          border: 0;
          border-radius: 16px;
          outline: none;
          padding: 16px 12px;
          height: 48px;
          display: flex;
          align-items: center;

          > span:last-child, > div span:last-child, > div > div, span:last-child > div p {
            font-size: 14px;
            font-weight: 700;
            margin-right: 8px;
          }

          &.stats {
            flex-direction: column;
            height: auto;
            gap: 16px;

            > div span:first-child, > div p {
              font-size: 12px;
            }

            > div div span:first-child {
              color: #777E90;
            }

            > div > div {
              display: flex;
              gap: 8px;
            }
          }

        }
      }
    }

    .pre-navigation,
    .next-navigation {
      @include pmx-lg {
        display: none;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #E3EAFA -18.75%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      width: 48px;
      height: 48px;
      z-index: 1;
      border-radius: 16px;
      cursor: pointer;
    }

    .next-navigation {
      transform: translate(-50%, -50%);
      bottom: 20%;
      left: 10%;
    }

    .pre-navigation {
      transform: translate(-25%, -50%);
      bottom: 20%;
      right: 0%;
    }
  }


  .extra-profit-box {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px;
    background-color: #F0F0F0;

    h3 {
      font-size: 20px;
      text-align: center;
      font-weight: 700;
    }

    p {
      font-size: 12px;
      text-align: center;
      font-weight: 700;
    }

    .inner {
      display: flex;
      background: linear-gradient(90deg, #5A8BFF 0%, #F0F0F0 49.91%);
      border-radius: 8px;
      position: relative;
      height: 136px;
      margin-top: 24px;

      .percent-image {
        height: 104px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-85%, -50%);
      }

      .bag-image {
        height: 160px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-15%, -50%);
      }
    }
  }
}
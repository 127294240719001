@import "core/styles/refrences/index";

.balance-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  height: 257px;
  max-height: 257px;
  padding: 16px;
  border-radius: 8px;
  background: $primary;
  box-sizing: border-box;
  white-space: nowrap;

  @include pmx-pre-lg {
    width: 100%;
  }

  .pmx-button {
    button {
      max-width: unset;
    }
  }

  .title {
    color: $white;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
  }

  .pmx-button {
    margin-top: 44px;
    span {
      font-weight: 700;
    }
  }

  .balance {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32px;

    p {
      font-size: 24px;
      font-weight: 700;
      color: $white;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      color: $white
    }
  }
}

@import '../../../../core/styles/refrences/index.scss';

.pmx-definitions {
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    width: 75%;
    margin-top: 48px;

    @include pmx-pre-lg {
        width: 90%;
    }

    h2 {
        font-size: 32px;
        font-weight: 700;

        @include pmx-pre-lg {
            font-size: 20px;
        }
    }

    p {
        font-size: 16px;
        font-weight: 500;

        @include pmx-pre-lg {
            font-size: 12px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: unset;
        margin: unset;
        margin-top: 32px;
        gap: 24px;

        @include pmx-pre-lg {
            gap: 16px;
        }

        li {
            display: flex;
            border: 1px solid #F0F0F0;
            border-radius: 16px;
            width: 100%;
            padding: 16px;
            gap: 16px;

            @include pmx-pre-lg {
                width: calc(100% - 32px);
            }

            .definition-number {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #E5F2FF;
                min-width: 40px;
                border-radius: 8px;
                font-size: 24px;
                font-weight: 700;
                color: #0080FF;
            }

            h4 {
                font-size: 24px;
                font-weight: 700;

                @include pmx-pre-lg {
                    font-size: 14px;
                }
            }

            p {
                font-size: 16px;
                font-weight: 700;
                color: #777E90;
                margin-top: 16px;

                @include pmx-pre-lg {
                    font-size: 12px;
                }
            }
        }
    }
}
@import "core/styles/refrences/index";

.pmx-ordercard-buttons {
  width: 100%;
  margin: 24px 0 24px;
  display: flex;
  gap: 24px;


  .pmx-button {
    width: auto;

    button {
      height: 64px;
      max-width: unset;

      @include pmx-pre-lg {
        height: 48px;
      }
    }

    button.ordercard_outlined {
      justify-content: center;

      span {
        color: $black;
        font-weight: 700;
      }
    }
  }

  .pmx-button:first-child {
    flex-grow: 5;
  }

  .pmx-button:last-child {
    flex-grow: 1;
  }

}
@import "core/styles/refrences/index.scss";

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;

@mixin background-gradient {
  background-image: linear-gradient(
                  90deg,
                  $base-color 0px,
                  $shine-color 40px,
                  $base-color 80px
  );
  background-size: 600px;
}

@keyframes shine-avatar {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

.order-details-modal-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 257px;
  width: 100%;
  background: $white;
  //   border: 1px solid $border !important;
  border-radius: 30px;
  box-sizing: border-box;

  @include pmx-pre-lg {
    width: 100%;
  }

  .badge-skeleton {
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
    height: 35px;
    width: 95px;
    border-radius: 8px;
    margin: auto;
  }

  .data-skeleton {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;

    .row-skeleton {
      display: flex;
      justify-content: space-between;
      gap: 50%;
      height: 20px;
      border-radius: 8px;

      .skeleton-part {
        flex: 1;
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        border-radius: 8px;
      }
    }
  }
}

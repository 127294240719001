@import "core/styles/refrences/index";

.pmx-antd-custom-input.ant-input {
  height: 64px;
  border-radius: 8px;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 700;
  padding: 11px 21px;
  border: 1px solid $border;

  &.error {
    border: 1px solid $thin-red;
  }

  @include pmx-pre-lg {
    height: 48px;
    font-size: 14px;
    padding: 11px 8px;
  }
}

.ant-input-infix {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 60%;
  top: 50%;
  display: flex;
  gap: 16px;
  z-index: 2;

  @include pmx-pre-lg {
    left: 55%;
    gap: 8px;
  }

  button {
    cursor: pointer;
    font-family: YekanBakh-En !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $lightBlueBorder;
    color: $blue;
    outline: none;
    border: none;
    border-radius: 8px;
    height: 32px;
    font-size: 16px;
    font-weight: 600;

    @include pmx-pre-lg {
      height: 20px;
      padding-inline: 4px;
      font-size: 12px;
    }
  }
}

.pmx-antd-custom-input.ant-input-affix-wrapper {
  height: 64px;
  border-radius: 8px;
  padding: 11px 21px;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid $border;


  @include pmx-pre-lg {
    height: 48px;
    font-size: 14px;
    padding: 11px 8px;
  }

  &.error {
    height: 80px;
    border: 1px solid $thin-red;
  }

  .ant-input {
    font-size: 20px;
    font-weight: 700;

    &.error {
      height: 80px;
      border: 1px solid $thin-red;
    }

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  .ant-input-prefix {

  }

  .ant-input-suffix {

  }
}


@import "../../../../../core/styles/refrences/index.scss";

.pmx-spot-rates {
  width: 75%;
  margin-inline: auto;
  margin-top: 120px;

  @include pmx-pre-lg {
    width: 90%;
    margin-top: 40px;
  }

  .spot-rates-table {
    margin-inline: auto;
    margin-top: 24px;
    width: 100%;

    .table-header {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;

      .header-cell {
        display: flex;
        justify-content: start;
        flex: 1;

        &:first-child {
        }

      }

      @include pmx-pre-lg {
        display: none;
      }
    }

    .header-divider {
      @include pmx-pre-lg {
        display: none;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      font-size: 14px;
      font-weight: 600;
      color: black;

      @include pmx-pre-lg {
        display: none;
      }

      &:hover {
        background-color: #f8f8f9;
      }

      > div {
        justify-content: start !important;
      }

      .currency-name {
        width: 15%;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 32px;
          height: 32px;
        }

        .col {
          display: flex;
          flex-direction: column;
          text-align: right;

          span:last-of-type {
            color: #b3b3b3;
            font-size: 10px;
            font-weight: 500;
          }
        }
      }

      .dollar-price,
      .sell-price,
      .buy-price,
      .changes,
      .buttons {
        display: flex;
        width: 15%;
        justify-content: center;
      }

      .chart-data {
        display: flex;
        width: max-content;
        justify-content: flex-start;
        margin-inline: auto;
      }

      .bullish {
        color: #36b274;
      }

      .bearish {
        color: #b23636;
      }

      .buttons {
        display: flex;
        justify-content: center;
        gap: 8px;

        a {
          text-decoration: none;
          padding: 4px 16px;
          border-radius: 16px;
          border: 1px solid #e6e8ec;
          background-color: white;
          cursor: pointer;
        }

        .buy-button {
          color: #36b274;

          &:hover {
            color: $white;
            background: #36b274;
            transition: .2s background-color;
          }
        }

        .sell-button {
          color: #b23636;

          &:hover {
            color: $white;
            background: #b23636;
            transition: .2s background-color;
          }
        }
      }
    }

    // 📱
    .mobile-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
      row-gap: 16px;

      @include pmx-lg {
        display: none;
      }

      .currency-name {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 32px;
          height: 32px;
        }

        .col {
          display: flex;
          flex-direction: column;
          text-align: right;

          span:last-of-type {
            color: #b3b3b3;
            font-size: 10px;
            font-weight: 500;
          }
        }
      }

      .col {
        display: flex;
        flex-direction: column;

        span {
          font-weight: 700;
          font-size: 10px;

          &:last-of-type {
            color: #b3b3b3;
            font-size: 10px;
            font-weight: 500;
          }
        }

        .changes {
          font-size: 12px;
        }

        .bullish {
          color: #36b274 !important;
        }

        .bearish {
          color: #b23636 !important;
        }
      }

      .start {
        align-items: flex-start;
      }

      .end {
        align-items: flex-end;
      }

      a {
        width: 100%;
        display: flex;
        text-decoration: none;
        padding: 4px 0;
        border-radius: 16px;
        border: 1px solid #e6e8ec;
        background-color: white;
        cursor: pointer;
        justify-content: center;
        margin-inline: auto;
        font-size: 14px;
        font-weight: 700;
      }

      .buy-button {
        color: #36b274;
      }

      .sell-button {
        color: #b23636;
      }
    }
  }

  .more {
    cursor: pointer;
    width: 100%;
    margin-top: 32px;
    border: 1px solid #f0f0f0;
    border-radius: 16px;
    display: flex;
    text-align: center;
    padding: 16px;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;

    @include pmx-pre-lg {
      width: 90%;
    }
  }
}

@import "core/styles/refrences/index.scss";

.pmx-bonus-help {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  width: 75%;
  margin-inline: auto;

  @include pmx-pre-lg {
    width: 90%;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }

  .bonus-steps {
    display: flex;
    margin-top: 64px;
    width: 100%;
    align-items: baseline;
    justify-content: center;
    position: relative;

    @include pmx-pre-lg {
      flex-direction: column;
    }

    #line {
      align-self: start;
      top: 32px;
      position: relative;

      @include pmx-pre-lg {
        display: none;
      }
    }

    #line-vertical {
      display: none;

      @include pmx-pre-lg {
        display: initial;
        align-self: center;
        margin: 16px 0;
      }
    }

    .step {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      @include pmx-pre-lg {
        width: 100%;
      }

      img {
        width: 64px;
        height: 64px;
      }

      h4 {
        font-size: 16px;
        font-weight: 700;
      }

      p {
        color: #777e90;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        width: 150%;

        @include pmx-pre-lg {
          width: 100%;
        }
      }
    }
  }
}

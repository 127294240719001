@import "../../../../../../../core/styles/refrences/index.scss";

.bankaccounts-modal {
  padding: 24px;
  width: 601px;
  transform: translate(-50%, -50%);
  left: 50% !important;
  min-height: unset;


  @include pmx-pre-lg {
    width: 90%;
    padding: 16px;

  }

  .modal-head {
    display: none;
  }

  .card-list {
    border: 1px solid $border;
    display: flex;
    box-sizing: border-box;
    margin: 0;
    flex-direction: column;
    padding: 8px 11px;
    border-radius: 8px;
    max-height: 300px;
    overflow-y: scroll;

    @include pmx-pre-lg {
      padding: 8px;
      max-height: 300px;
      overflow-y: scroll;
    }

    .ant-divider {
      margin: 8px 0;
    }

    li {
      list-style: none;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
      height: 64px;
      padding: 0 16px;
      transition: 0.2s;
      width: 100%;
      box-sizing: border-box;

      @include pmx-pre-lg {
        padding: 0 8px;
        height: 48px;
      }

      &:first-child {
        margin-top: 0px;
      }

      &:hover {
        background-color: $extraLightBlue;
        cursor: pointer;
        transition: 0.2s;
      }

      p,
      span {
        display: flex;
        font-size: 20px;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 16px;
        }
      }

      p {
        margin-right: 16px;

        &:nth-child(3) {
          flex: 1;
        }
      }

      .card-number {
        direction: ltr;
        text-align: right;
        justify-content: end;
        display: flex;
      }

      .remove-icon {
        background-color: $light-red;
        border-radius: 4px;
        width: 36px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;


        &:hover {
          background: $delete-icon-hover-red;

          svg {
            path {
              stroke: $white;
            }
          }
        }

        svg {
          height: 18px;


        }
      }
    }
  }
}

.pmx-order-card .pmx-enter-card-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: auto;

  .choose-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h3 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 24px;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    > p {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      text-align: right;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }

    .choose-button {
      width: 100%;
      margin-top: 16px;

      button {
        max-width: unset;
        justify-content: center;
        align-items: center;
      }
    }

    .card-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
      width: calc(100% - 32px);
      height: 64px;
      padding: 0 16px;
      margin-top: 8px;
      transition: 0.2s;

      @include pmx-pre-lg {
        height: 48px;
      }

      &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
        transition: 0.2s;
      }

      p,
      span {
        display: flex;
        font-size: 20px;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }

      p {
        margin-right: 16px;

        &:nth-child(3) {
          flex: 1;
        }
      }

      .card-number {
        direction: ltr;
        text-align: right;
        justify-content: end;
        display: flex;
      }

      .down-icon {
        background-color: #fafafa;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include pmx-pre-lg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .add-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h3 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 24px;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    > p {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      text-align: right;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }

    .pmx-input {
      width: 100%;
      margin-top: 16px;
      box-sizing: border-box;
      height: 64px;

      @include pmx-pre-lg {
        height: 48px;
      }


      input {
        height: 64px;
        font-size: 20px;
        font-weight: 700;
        box-sizing: border-box;

        @include pmx-pre-lg {
          height: 48px;
          font-size: 14px;
        }
      }

      .adornments {
        width: fit-content;

        img {
          @include pmx-pre-lg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .warning {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
      font-size: 16px;
      color: #d4781c;
      font-weight: 500;

      @include pmx-pre-lg {
        font-size: 12px
      }

      svg {
        transform: translateY(-2px);
      }
    }

    .card-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
      width: calc(100% - 32px);
      height: 91px;
      padding: 0 16px;
      margin-top: 8px;
      transition: 0.2s;

      &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
        transition: 0.2s;
      }

      p,
      span {
        display: flex;
        font-size: 20px;
        font-weight: 700;

        @include pmx-pre-lg {

        }
      }

      p {
        margin-right: 16px;

        &:nth-child(3) {
          flex: 1;
        }
      }

      .down-icon {
        background-color: #fafafa;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }


  }
}

@import "../../../../../../../core/styles/refrences/index.scss";

.pmx-payment-result-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: auto;
  position: relative;

  *:not(.payment-mask) {
    z-index: 1;
  }

  .payment-mask {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: 0;

  }

  .result-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 32px;
    z-index: 1;

    img {
      width: 64px;
      height: 64px;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-top: 16px;
    }

    p {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 500;
      color: #909090;
      text-align: center;
    }
  }

  .result-info {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #f9fcff;
    padding: 24px;
    z-index: 1;
    margin-top: 48px;

    .result-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 700;

        &:first-child {
          opacity: 0.5;
        }
      }

      .date {
        direction: ltr;
      }

      #tracking-code {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #3772ff;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .result-timer {
    z-index: 1;
    margin-top: 24px;

    p {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
    }

    span {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: #3772ff;
      margin-left: 4px;

      svg {
        transform: translateY(-3px);
      }
    }
  }

  .buttons {
    width: 100%;
    margin: 24px 0 24px;
    display: flex;
    gap: 24px;
    z-index: 1;

    .pmx-button {
      width: auto;

      button {
        height: 64px;
        max-width: unset;

        @include pmx-pre-lg {
          height: 48px;
        }
      }

      button.ordercard_outlined {
        justify-content: center;

        span {
          color: $black;
          font-weight: 700;
        }
      }
    }

    .pmx-button:first-child {
      flex-grow: 5;
    }

    .pmx-button:last-child {
      flex-grow: 1;
    }
  }
}

@import '../../../../../core/styles/refrences/index.scss';

.pmx-navbar-home {
  display: flex;
  width: 75%;
  height: 72px;
  background-color: white;
  margin-inline: auto;
  border-radius: 16px;
  position: relative;
  z-index: 1;

  @include pmx-pre-lg {
    display: none;
  }

  img {
    margin-inline: 16px;
    cursor: pointer;
  }

  hr {
    margin: auto 0;
    height: 50%;
    opacity: 0.3;
  }

  .navigation {
    display: flex;
    width: 100%;
    list-style: none;
    margin: 0;
    align-items: center;
    justify-content: flex-start;

    li {
      display: flex;
      height: 100%;
      align-items: center;
      transition: background-color 0.2s ease, color 0.2s ease;

      &:hover {
        background-color: hsl(223deg 3% 50% / 8%);
        border-radius: 10px;
        a {
          color: black;
          transition: color 0.2s ease;
        }
      }

      a {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: rgba(123, 125, 130, 1);
        position: relative;
        font-weight: 700;
        padding: 0 16px;

        span {
          display: none;
        }

        &.active {
          color: black;

          span {
            display: flex;
            width: 75%;
            height: 4px;
            bottom: 0;
            position: absolute;
            background: rgba(55, 114, 255, 1);
            border-radius: 8px 8px 0px 0px;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    margin-inline: 16px;
    justify-content: flex-end;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      border: 1px solid #e6e6e6;
      border-radius: 16px;
      height: 40px;
      box-sizing: border-box;
      white-space: nowrap;
      cursor: pointer;

      &.phone {

        .col {
          align-items: start;
          padding-right: 8px !important;
        }

        svg {
          animation: vibration 0.7s ease infinite;
        }

        @keyframes vibration {
          0%, 100% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(-5deg);
          }
          75% {
            transform: rotate(5deg);
          }
        }
      }

      &:first-child {
        padding: 4px 4px 4px 20px;

        .col {
          padding: 4px;
          display: flex;
          flex-direction: column;

          span {
            font-size: 10px;
            font-weight: 600;

            &:first-child {
              color: black;
            }

            &:last-child {
              direction: ltr;
              color: #245bf3;
            }
          }
        }
      }

      &:nth-child(2) {
        padding: 4px 20px;
        color: black;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }

      &:nth-child(3) {
        padding: 4px 20px;
        color: white;
        background-color: #3772ff;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}

.pmx-mobile-navbar-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-inline: auto;
  position: relative;
  height: 44px;
  z-index: 1;

  @include pmx-lg {
    display: none;
  }

  .pmx-navbar-logo {
    transform: translate(-24px, -10px);
    cursor: pointer;
  }

  .actions {
    display: flex;
    gap: 8px;

    svg {
      border: 1px solid #FFFFFF33;
      border-radius: 10px;
      padding: 8px;

      &:first-child {
        fill: white;
      }
    }

  }
}

@import "core/styles/refrences/index.scss";

.pmx-login-register {
  .code {
    display: flex;
    width: 100%;
    position: relative;

    label {
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      top: -40px;
      right: 0;
      color: $blue;
    }

    .code-input {
      display: flex;
      direction: ltr;
      width: 100% !important;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > input {
          background: $outlinedBg;
          border-radius: 20px;
          border: 1px solid $border;
          font-size: 48px;
          color: $black;
          padding: 8px;

          @include pmx-pre-md {
            margin: 0 5px;
          }
        }
      }
    }

    .timer {
      align-self: flex-end;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 40px;

      > div {
        left: auto !important;
        top: auto !important;

        > div {
          position: relative !important;
          height: 28px !important;
          width: 65px !important;
        }
      }
    }
  }

  .pmx-input-material {
    margin-top: 70px;
  }
}

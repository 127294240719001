@import "core/styles/refrences/index";

.circle {
  position: relative;
  align-self: baseline;
  white-space: nowrap;
  opacity: 0.5;
  width: 50px;

  .bottomText {
    text-align: center;
    color: black;
    font-weight: "800";
    line-height: "22px";
  }

  .inner {
    text-align: center;
    margin: auto;
    justify-content: center;
    font-size: xx-large;

    svg {
      circle {
        fill: #B3B3B3;
      }
    }
  }

  &.reached {

    opacity: 1;

    .bottomText {
      color: black;
      font-weight: 600;
    }

    .inner {
      svg {
        circle {
          fill: #3772FF;
        }
      }
    }
  }
}

@import '../../../../../core/styles/refrences/index.scss';

.pmx-contact-us {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 64px auto;

  @include pmx-pre-lg {
    width: 90%;
    margin: 32px auto;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    text-align: right;

    @include pmx-pre-lg {
      font-size: 20px;
    }
  }

  p {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    text-align: right;

    @include pmx-pre-lg {
      font-size: 12px;
      margin-top: 16px;
    }
  }

  .communication {
    display: flex;
    width: 100%;
    margin-top: 32px;
    gap: 16px;
    justify-content: center;

    @include pmx-pre-lg {
      flex-wrap: wrap;
    }

    .communication-item {
      border: 1px solid #E6E6E6;
      border-radius: 16px;
      padding: 24px;
      width: 100%;
      flex: 0.5;
      display: flex;
      flex-direction: column;
      align-items: start;

      @include pmx-pre-lg {
        padding: 16px;
        align-items: center;
      }

      .call-icon, .email-icon, .telegram-icon, .location-icon, .chat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border-radius: 50%;

        @include pmx-pre-lg {
          width: 48px;
          height: 48px;
          margin: 0 auto;

          &:nth-child(1) {
            svg {
              width: 20px;
            }
          }

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .call-icon {
        background-color: #DCF5EA;
      }

      .email-icon {
        background-color: #E5F2FF;
      }

      .telegram-icon {
        background-color: #F4E8FF;
      }

      .location-icon {
        background-color: #F5DCDC;
      }

      .chat-icon {
        background-color: $dark-yellow;

        @include pmx-pre-lg {
          width: 100%;
          border-radius: 8px;
        }
      }


      @include pmx-pre-lg {
        width: calc(50% - 48px);

        &:last-child {
          width: 100%;
          flex: 1;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 700;
        margin-top: 24px;

        @include pmx-pre-lg {
          font-size: 16px;
          text-align: center;
        }
      }

      a {
        text-decoration: none;
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;
        color: #777E90;
        white-space: nowrap;

        @include pmx-pre-lg {
          font-size: 12px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
  }
}

// mobile-menu.scss
@import "../../core/styles/refrences/index.scss";

.mobile-dropdown {


  &.open {
    bottom: 100px !important;
    max-height: max-content;
    transition: 0.5s;
  }

  max-height: 0;
  font-size: 12px;

  .mobile-menu-item {
    padding-inline: 24px;
    font-weight: 500;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: right;

    &.active {
      &::before {
        content: "";
        position: absolute;
        right: 0;
        width: 5px;
        height: 32px;
        background: $blue;
        margin: auto;
        border-radius: 4.5px 0px 0px 4.5px;
      }
    }

    &:hover {
      font-weight: 900;
      border-radius: 12px 0px 0px 12px;
      background-color: $extraLightBlue;
      width: max-content;
    }
  }

  background: $white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  position: absolute;
  padding: 10px 0px;
  border: 1px solid #c2d1f7;
  border-radius: 12px 12px 0px 0px;
  bottom: -100px;
  transition: 0.5s;

  z-index: 101;
  width: 100%;
  margin: auto;

  svg {
    width: 32px;
    height: 32px;
    fill: $blue;
  }

  @include pmx-lg {
    display: none;
  }
}

.pmx-mobile-menu {
  font-size: 12px;
  border: 1px solid #c2d1f7;
  border-radius: 12px 12px 0px 0px;
  position: fixed;
  bottom: -2px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 100px;
  transition: background 0.3s;
  z-index: 101;

  &.open {
    border: none;
    margin-bottom: 1px;
    margin-right: 1px;
    border-radius: 0;
  }

  .mobile-menu-list {
    margin: 0 !important;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-grow: 1;
    height: 100% !important;

    li {
      height: 100%;
      flex: 1;
    }

    .mobile-menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $blue;
      cursor: pointer;
      flex: 1;
      height: 100%;
      transition: background 0.3s;

      &.active {
        position: relative;
        color: $blue;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 48px;
          height: 5px;
          background: $blue;
          margin: auto;
          border-radius: 0 0 4.5px 4.5px;
        }
      }

      // &.open {
      //   position: relative;
      //   color: blue;

      //   &::before {
      //     content: "";
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     right: 0;
      //     width: 48px;
      //     height: 5px;
      //     background: $blue;
      //     margin: auto;
      //     border-radius: 0 0 4.5px 4.5px;
      //   }
      // }
    }
  }

  @include pmx-lg {
    display: none;
  }
}

@import "core/styles/refrences/index";

.user-level-box {
  display: flex;
  background: radial-gradient(
    100% 100% at 50% 100%,
    #739dff 3.12%,
    #3772ff 100%
  );
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  width: 25%;
  height: 257px;
  position: relative;
  border-radius: 16px;

  @include pmx-pre-lg {
    width: 100%;
  }

  .buttons {
    position: absolute;
    display: flex;
    gap: 16px;
    bottom: 10%;
    left: 15%;
    right: 15%;

    .pmx-button {
      z-index: 11;
      border-radius: 8px;
      background: #fff;

      button {
        justify-content: center;

        span {
          color: black;
          font-size: 18px;
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }

    > img.up-button {
      margin-right: 11px;
      z-index: 11;
      cursor: pointer;
    }
  }

  > img.gold-star {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 35%;
    right: 10%;
  }

  > img.white-star {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 25%;
    left: 10%;
  }

  > img.person {
    padding: 5%;
    margin-inline: auto;
    height: 193px;
  }
}

@import "../../../../../../../core/styles/refrences/index.scss";

.pmx-order-card .pmx-enter-wallet-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @include pmx-pre-lg {
    height: auto;
  }

  .choose-wallet-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h3 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 24px;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    > p:first-child {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      text-align: right;
      margin-top: 8px;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }

    .choose-network-button {
      background-color: #f5f8ff;
      border: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      height: 64px;
      padding: 0 21px;
      margin-top: 16px;
      cursor: pointer;

      @include pmx-pre-lg {
        height: 48px;
      }

      svg {
        @include pmx-pre-lg {
          width: 24px;
          height: 24px;
        }
      }

      span {
        color: #3772ff;
        font-size: 20px;
        font-weight: 700;
        font-family: YekanBakh-En !important;
        direction: ltr;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }
    }

    .ant-input-affix-wrapper {
      margin-top: 16px;
      font-family: YekanBakh-En !important;

      &::placeholder {
        text-align: right;
      }

      text-align: left;
    }
  }

  .add-wallet-button {
    margin-top: 16px;

    button {
      width: 100%;
      max-width: unset;
      justify-content: center;
    }
  }

  .choose-network-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 331px;
    overflow: auto;

    .pmx-button {
      button {
        max-width: unset;
        margin-top: 16px;
        padding: 8px 16px;

        span {
          color: black;
          font-weight: 900;
          font-size: 20px;
        }
      }
    }

    ul {
      padding: unset;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      scrollbar-width: 2px;

      li {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        padding: 8px 16px;

        span {
          font-size: 20px;
          font-weight: 900;
          font-family: YekanBakh-En !important;
          direction: ltr;
          transform: translateY(2px);

          @include pmx-pre-lg {
            font-size: 12px;
          }
        }

        svg {
          @include pmx-pre-lg {
            width: 14px;
            height: 12px;
          }
        }
      }
    }
  }

  .add-wallet-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h3 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 24px;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    > p:first-child {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      text-align: right;
      margin-top: 8px;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }
  }

  .choose-wallet-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-radio-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      @include pmx-pre-lg {
        flex-direction: column;
      }

      .ant-radio-wrapper {
        span {
          font-size: 18px !important;
          font-weight: 700;
          white-space: nowrap;

          @include pmx-pre-lg {
            font-size: 16px;
          }
        }
      }
    }

    .saved-wallet-input {
      cursor: pointer;

      input {
        cursor: pointer;
      }

      .ant-input-suffix {
        svg {
          @include pmx-pre-lg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }


    > h3 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 24px;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    > p:first-child {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      text-align: right;
      margin-top: 8px;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }

    ul {
      list-style: none;
      width: 100%;
      border: 1px solid $border;
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;
      max-height: 200px;
      overflow-y: auto;

      .ant-divider {
        margin: unset;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 16px;
        border-radius: 4px;
        position: relative;
        z-index: 2;

        &:hover {
          background: $extraLightBlue;
        }

        &.active {
          background: $extraLightBlue;
        }

        div {
          &:first-child {
            p {
              &:first-child {
                font-size: 20px;
                font-weight: 700;

                @include pmx-pre-lg {
                  font-size: 16px;
                }
              }

              &:last-child {
                font-size: 16px;
                font-weight: 600;
                color: $dark-grey;

                @include pmx-pre-lg {
                  font-size: 12px;
                }
              }
            }
          }

          &:last-child {
            width: 36px;
            height: 36px;
            border-radius: 4px;
            background: $light-red;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;

            svg {
              path {
              stroke: $delete-icon-hover-red
              }
            }

            &:hover {
              background: $delete-icon-hover-red;

              svg {
                path {
                  stroke: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../core/styles/refrences/index.scss";

.pmx-help-page {
  .ant-divider {
    min-width: unset;
    width: 100%;

    @include pmx-pre-lg {
      margin: 16px auto;
      display: none;
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-faq-main {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-inline: auto;
  margin-top: 120px;

  @include pmx-pre-lg {
    width: 90%;
    margin-top: 40px;
  }

  .faq-categories {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @include pmx-pre-lg {
      flex-wrap: wrap;
    }

    .faq-category {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 16px;
      border: 1px solid #f0f0f0;
      height: 180px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease, stroke 0.3s ease, color 0.3s ease;

      @include pmx-pre-lg {
        flex: unset;
        width: calc(50% - 18px);
        height: 100px;

        &:last-of-type {
          width: 100%;
          flex: 1;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }

      span {
        color: $dark-grey;
        font-size: 18px;
        font-weight: 700;
      }

      &:nth-child(2) {
        &.active {
          svg {
            path {
              fill: white;
            }
          }
        }

        &:hover {
          svg {
            path {
              fill: white;
            }
          }
        }
      }

      &:hover {
        background: #3772ff;

        svg {
          path {
            stroke: white;
          }
        }

        span {
          color: white;
        }

        transition: background-color 0.3s ease, stroke 0.3s ease,
        color 0.3s ease;
      }

      &.active {
        background: #3772ff;
        cursor: unset;

        svg {
          path {
            stroke: white;
          }
        }

        span {
          color: white;
        }
      }
    }
  }

  .faq-items {
    .ant-collapse {
      display: flex;
      flex-direction: column;
      border: none;
      margin-top: 32px;
      gap: 16px !important;
      background: none;

      @include pmx-pre-lg {
        margin-top: 24px;
      }

      .ant-collapse-item {
        background-color: white !important;
        border-radius: 24px;
        border: 1px solid #f0f0f0;

        .ant-collapse-header {
          display: flex;
          align-items: center;
          height: 80px;

          .ant-collapse-header-text {
            font-size: 18px;
            font-weight: 700;
            color: $dark-grey;

            @include pmx-pre-lg {
              font-size: 14px;
            }
          }
        }

        .ant-collapse-content {
          height: auto;

          .ant-collapse-content-box {
            border-radius: 0px 0px 24px 24px;
            border: 1px solid #f0f0f0;

            p {
              font-size: 16px;
              font-weight: 500;
              color: $dark-grey;

              @include pmx-pre-lg {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .ant-divider {
    margin: 80px 0 0;

    @include pmx-pre-lg {
      margin-top: 24px;

    }
  }

  .support {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    @include pmx-pre-lg {
      flex-direction: column;
      margin-top: 24px;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 700;

      @include pmx-pre-lg {
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #e5f2ff;
      color: #3772ff;
      font-size: 16px;
      font-weight: 700;
      height: 57px;
      padding: 0 16px;
      border-radius: 8px;
      gap: 8px;
      cursor: pointer;

      @include pmx-pre-lg {
        font-size: 14px;
        margin-top: 16px;
        justify-content: center;
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-datepicker {
  width: 601px;
  top: 5%;
  left: calc(50% - 300px);

  @include pmx-pre-lg {
    width: 90%;
    left: 5%;
  }

  .modal-head {
    display: none;
  }

  .ant-input-affix-wrapper {
    height: 64px;
    border-radius: 16px;
    padding: 11px 21px;

    .ant-input-suffix {
      .select-country {
        display: flex;

        img {
          width: 40px;
          height: 40px;
        }

        .code {
          font-size: 24px;
          font-weight: 700;
          margin-left: 16px;
        }

      }

      .vertical-divider {
        display: block;
        height: 100%;
        width: 1px;
        border-right: 1px solid $lightGreyBorder;
        margin: 0 24px;
        max-height: 43px;
      }
    }
  }

  .options-row {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .dropdowns {
      display: flex;
      align-items: center;
      gap: 24px;

      @include pmx-pre-lg {
        gap: 16px;
      }

      .dropdown-selector {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $lightBlueBorder;
        border-radius: 8px;
        width: auto;
        height: 64px;
        padding: 14px;
        gap: 24px;
        cursor: pointer;

        @include pmx-pre-lg {
          height: 52px;
          padding: 10px;
        }
      }
    }
  }

  .year-list, .day-list, .month-list {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
  }

  .year-list {
    height: 300px;
    overflow-y: scroll;

    @include pmx-pre-lg {
      height: 250px;
    }
  }

  .day-list {
    @include pmx-pre-lg {
      height: 250px;
      overflow-y: scroll;
    }
  }


  .year, .month, .day {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;


    &.active {
      background: $blue !important;
      color: $white !important;
    }

    &:hover {
      background: $lightGray;
      color: $black;
    }

    > span {
      color: $white;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .day {
    padding: 11px 21px;
    height: 64px;
    width: 14%;

    @include pmx-pre-lg {
      padding: 16px;
      height: 48px;
    }
  }

  .month {
    width: 33%;
    padding: 11px 21px;
    height: 64px;
  }

  .year {
    width: 25%;
    padding: 11px 21px;
    height: 64px;
  }

  .buttons {
    width: 100%;
    display: flex;
    gap: 24px;


    .pmx-button {
      width: auto;
      margin-top: 40px;

      button {
        height: 64px;
        max-width: unset;

        @include pmx-pre-lg {
          height: 48px;
        }
      }

      button.ordercard_outlined {
        justify-content: center;

        span {
          color: $black;
          font-weight: 700;
        }
      }
    }

    .pmx-button:first-child {
      flex-grow: 5;
    }

    .pmx-button:last-child {
      flex-grow: 1;
    }
  }

}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-landing-help {
  display: flex;
  background: #526bfc;
  width: 100%;
  height: 480px;
  position: relative;
  flex-direction: column;

  @include pmx-pre-lg {
    height: 400px;
  }

  .pmx-navbar-home {
    margin-top: 32px;
    z-index: 1;
  }

  .pmx-mobile-navbar-home {
    margin-top: 32px;
  }
  
  #banner {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
  }

  .hint-box {
    display: flex;
    flex-direction: column;
    font-size: 32px;
    font-weight: 700;
    color: white;
    width: 30%;
    margin: auto;
    z-index: 1;
    transform: translateY(-32px);

    @include pmx-pre-lg {
        width: 100%;
    }
    
    h2 {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        
        @include pmx-pre-lg {
            font-size: 20px;
        }
    }
    
    .ant-input-affix-wrapper {
      margin-top: 32px;
      height: 54px;
      border-radius: 16px;

      @include pmx-pre-lg {
        width: 85%;
        margin-inline: auto;
        margin-top: 16px;
      }

      input {
        font-size: 14px;
        direction: rtl;
        &::placeholder {
          color: #777e90;
        }
      }
    }
  }

}

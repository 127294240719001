@import "core/styles/refrences/index";

.pmx-referral {

  .tables {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-top: 32px;

    .activities {
      margin-right: 32px;
    }

    .payouts,
    .activities {
      width: 50%;

      h3 {
        font-size: 20px;
        font-weight: 700;
      }

      .pmx-card {
        margin-top: 16px;
        padding: 0;

        .head {
          padding: 24px 24px 16px;

          a {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 14px;
            font-weight: 600;
          }
        }

        * {
          box-sizing: border-box;
        }
      }

      table {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: start;
        align-items: space-between;

        .divider {
          display: inline-flex;
          width: 100%;
          height: 1px;
          border-top: 1px solid $border;
        }

        thead {
          box-sizing: border-box;
          padding: 24px;
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          border-top: 1px solid #f0f0f0;
          border-bottom: 1px solid #f0f0f0;

          th {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 700;
            width: calc(100% / 4);
          }

          th:first-child {
            justify-content: start;
          }

          th:last-child {
            justify-content: end;
          }
        }

        tbody {
          display: flex;
          flex-direction: column;
          width: 100%;

          tr {
            display: flex;
            justify-content: space-evenly;
            gap: 16px;
            padding: 12px 24px;

            td {
              display: flex;
              justify-content: center;
              align-items: center;
              width: calc(100% / 4);
              font-size: 13px;
              font-weight: 400;
              white-space: nowrap;

              img {
                width: 21px;
                height: 21px;
              }


            }

            td:first-child {
              justify-content: start;
            }

            td:last-child {
              justify-content: end;
            }
          }
        }
      }
    }

    //tables
    @include pmx-pre-lg {
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 38px;

      .activities {
        margin-top: 0;
      }

      .payouts {
        margin-top: 38px;
      }

      .payouts,
      .activities {
        margin-right: -48px;
        width: 100%;

        .pmx-card {
          width: 100vw;
          border: none;
          border-radius: 0px;
        }

        h3 {
          font-size: 16px;
          margin-right: 24px;
          font-weight: 900;
        }

        table {
          thead {
            font-size: 12px;

            th {
              font-size: 12px;
            }
          }

          td {
            .pmx-badge {
              > div {

                height: 23px;
              }

              span {
                font-size: 10px;
              }
            }
          }

          tbody tr td {
            font-size: 12px;
          }

          tbody tr td:nth-child(2) {
            font-size: 10px;
          }
        }
      }
    }
  }

  @include pmx-pre-lg {
    margin-bottom: 140px;
  }
}

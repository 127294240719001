@import "../../../../../../../core/styles/refrences/index.scss";

.pmx-enter-voucher-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 300px;

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .ant-input {
    font-family: YekanBakh-En !important;
  }

  .checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 16px;
    margin-top: 24px;

    p {
      font-size: 16px;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  background: $white;
  border: 1px solid $border;
  position: relative;

  &.secondary {
    background: $secondaryCard;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;

    h4 {
      display: flex;
      width: fit-content;
      font-size: 20px;
    }

    .pmx-button {
      width: fit-content;
      margin: unset;

      button {
        height: 48px;
        background: white;
        span {
          font-size: 16px;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
    }
  }

  .body {
    display: flex;
    justify-content: start;
    align-items: start;
  }

  .card-divider {
    width: calc(100% + 48px);
    transform: translateX(24px);
    margin: unset;
    margin-top: 24px;
    
    @include pmx-pre-lg {
      width: calc(100% + 32px);
      transform: translateX(16px);
    }
  }
}

@include pmx-pre-lg {
  .pmx-card {
    .head {
      h4 {
        font-size: 16px;
      }
    }
  }
}

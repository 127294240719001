@import "core/styles/refrences/index";

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset;
  }

  40%,
  100% {
    background-position: 140px + $avatar-offset;
  }
}

.invite-box-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 257px;
  width: 25%;
  background: $white;
  border: 1px solid $border !important;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;

  @include pmx-pre-lg {
    width: 100%;
  }

  .title-skeleton {
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: shine-avatar 1.6s infinite linear;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    width: 90px;
    margin: 0 auto;
    height: 20px;
    border-radius: 8px;
  }

  .button-skeleton {
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
    max-width: unset;
    height: 48px;
    border-radius: 8px;
  }

  .ant-divider {
    margin: unset;
  }

  .list-skeleton {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .row-skeleton {
      display: flex;
      justify-content: space-between;

      p {
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        font-size: 14px;
        font-weight: 700;
        color: $blue;
        width: 80px;
        height: 12px;
        border-radius: 8px;
      }

      .value-skeleton {
        font-size: 20px;
        font-weight: 700;
        color: $blue;
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        font-size: 12px;
        font-weight: 400;
        color: $blue;
        width: 40px;
        height: 12px;
        border-radius: 8px;
      }
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-instructions {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-inline: auto;
  margin-top: 64px;

  @include pmx-pre-lg {
    width: 90%;
    margin-top: 32px;
  }

  @include pmx-pre-lg {
    margin-top: 32px;
  }

  h3 {
    font-weight: 700;
    font-size: 32px;

    @include pmx-pre-lg {
      font-size: 20px;
    }
  }

  .instructions-categories {
    display: flex;
    flex-direction: column;

    &.active {
      display: none;
    }

    .categories {
      display: flex;
      gap: 16px;
      margin-top: 32px;

      @include pmx-pre-lg {
        flex-direction: column;
      }

      .instruction-category {
        display: flex;
        align-items: center;
        gap: 16px;
        border: 1px solid #f0f0f0;
        border-radius: 16px;
        padding: 8px;
        width: 100%;
        cursor: pointer;
        box-sizing: border-box;

        @include pmx-pre-lg {
          gap: 16px;
        }

        span {
          font-size: 18px;
          font-weight: 700;

          @include pmx-pre-lg {
            font-size: 16px;
          }
        }

        .category-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #3772ff;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          color: $white;

          @include pmx-pre-lg {
            width: 32px;
            height: 32px;
          }
        }

        .active {
        }
      }
    }
  }

  .category-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
    }

    .pmx-button {
      width: fit-content;
      margin: unset;

      button {
        height: 48px;
        background-color: unset;

        span {
          color: black;

          @include pmx-pre-lg {
            font-size: 14px;
          }
        }
      }
    }
  }

  .category-content {
    display: flex;
    gap: 32px;

    aside {
      border-radius: 16px;
      border: 1px solid #f0f0f0;
      padding: 16px;

      @include pmx-pre-lg {
        display: none;
      }

      h4 {
        color: #3772ff;
        font-size: 24px;
        font-weight: 700;
      }

      .categories {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;

        .instruction-category {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #f0f0f0;
          padding: 4px 8px;
          border-radius: 8px;
          height: 48px;
          width: 232px;
          gap: 8px;
          cursor: pointer;

          &.selected {
            background-color: #3772ff;

            span {
              color: white;
            }

            .category-icon {
              svg {
                path {
                  stroke: white;
                }
              }

              &:nth-child(2) {
                svg {
                  path {
                    fill: white;
                  }
                }
              }
            }

            cursor: unset;
          }

          #active-arrow {
            path {
              stroke: white;
            }
          }

          .category-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              path {
                stroke: black;
              }
            }

            &:nth-of-type(2) {
              svg {
                path {
                  fill: black;
                }
              }
            }
          }

          span {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            font-size: 18px;
            font-weight: 700;
          }

          &.selected {
          }
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: unset;
      gap: 16px;
      margin-top: unset;
      width: 100%;

      @include pmx-pre-lg {
        margin-top: 48px;
      }

      li {
        display: flex;
        height: 72px;
        justify-content: space-between;
        border: 1px solid #f0f0f0;
        padding: 4px 16px;
        border-radius: 16px;
        align-items: center;

        span {
          font-size: 16px;
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: #e5f2ff;
          padding: unset;
          cursor: pointer;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .help-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
    }

    .pmx-button {
      width: fit-content;
      margin: unset;

      button {
        height: 48px;
        background-color: unset;

        span {
          color: black;

          @include pmx-pre-lg {
            font-size: 14px;
          }
        }
      }
    }
  }

  .pmx-popular-instructions {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-inline: auto;
    margin-top: 64px;

    @include pmx-pre-lg {
      margin-top: 32px;
    }

    h3 {
      font-size: 32px;
      font-weight: 700;

      @include pmx-pre-lg {
        font-size: 20px;
      }
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      list-style: none;
      padding: unset;
      margin-top: 32px;
      column-gap: 32px;
      row-gap: 16px;

      li {
        display: flex;
        height: 72px;
        justify-content: space-between;
        border: 1px solid #f0f0f0;
        padding: 4px 16px;
        border-radius: 16px;
        align-items: center;

        span {
          font-size: 16px;
          font-weight: 700;

          @include pmx-pre-lg {
            font-size: 14px;
          }
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: #e5f2ff;
          padding: unset;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

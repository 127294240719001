@import "core/styles/refrences/index";

.password-form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.pmx-security .password-form {
  width: 100%;
  padding: 40px;
  height: 100%;

  .head {
    h4 {
      font-size: 24px;
      font-weight: 900;
      color: $black;
      text-align: center;
    }
  }

  .body {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    height: 100%;
    justify-content: space-between;

    .pmx-button {
      width: 100%;
      margin-top: 40px;

      button {
        height: 64px;
        max-width: unset;

        span {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .row {
      display: flex;
      margin-top: 16px;
      box-sizing: border-box;
      width: 100%;

      .pmx-input {
        height: 64px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        max-width: unset;

        input {
        }
      }

      textarea {
        background-color: $outlinedBg;
        width: 100%;
        font-size: 16px;
        outline: none;
        border: 0;
        background: none;
        border: 1px solid $border;
        background: $outlinedBg;
        border-radius: 20px;
        height: 136px;
        resize: none;
        font-family: YekanBakh;
        padding: 22px 24px;

        &::placeholder {
          color: $lightPurple;
          opacity: 1; /* Firefox */
        }
      }

      .col {
        box-sizing: border-box;
        width: 50%;

        .pmx-input {
          width: 100%;
        }
      }

      .col:last-child {
        margin-right: 16px;
      }
    }

    .row:first-child {
      margin-top: 0;
    }
  }
}

.pmx-security .pmx-card:last-child {
  //margin-top: 32px;
}

.pmx-security .pmx-card.completed {
  padding: 16px;
  height: 194px;
  margin-top: 40px;
  width: 100%;

  .body {
    background: $secondaryCard;
    padding: 24px 24px 24px 56px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;

    h4 {
      color: $blue;
      font-size: 20px;
      font-weight: 700;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-right: 16px;

      p {
        font-size: 14px;
        color: $blue;
        font-weight: 700;
      }
    }

    img {
      position: relative;
      bottom: 64px;
      width: 159px;
      height: 225px;
    }
  }
}

@include pmx-pre-lg {
  .password-form-container {
    min-height: unset;

    .password-form {
      form {

        .pmx-button {
          margin-top: 24px;
        }
      }
    }
  }
}
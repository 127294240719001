@import "core/styles/refrences/index";

.pmx-login {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100vh;
  padding: 5%;

  .slider-container {
    width: 510px;
    overflow: hidden;
    position: relative;
    height: 100%;
    box-sizing: border-box;

    .slider {
      box-sizing: border-box;
      height: 100%;

      .swiper-wrapper {
        display: flex;
        align-items: center;
      }

      .swiper-slide {
        display: flex;
        align-items: center;

        .card {
          width: 100%;
          max-width: 440px;
          height: 100%;
          position: relative;

          img {
            width: 100%;
            margin-top: auto;
            margin-bottom: auto;
            height: 100%;
          }

          span,
          p {
            color: $white;
          }

          span {
            position: absolute;
            bottom: 209px;
            right: 32px;
            font-size: 2em;
            font-weight: 700;
          }

          p {
            position: absolute;
            bottom: 120px;
            right: 32px;
            font-size: 1em;
            font-weight: 400;
            width: auto;
          }
        }
      }

      svg {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 3em;
        height: 3em;
        cursor: pointer;
        z-index: 10;

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    padding-right: 174px;
    margin-top: auto;
    margin-bottom: auto;

    .title {
      h1 {
        font-size: 48px;
        font-weight: 900;
      }

      h3 {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .fields {
      margin-top: 64px;
    }

    .buttons {
      margin-top: 40px;

      .pmx-button {
        button {
          height: 64px;
          max-width: unset;

          span {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }

    > a {
      margin-top: 24px;
      font-size: 20px;
      color: $blue;
      text-align: center;
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.invite-friends {
  margin-right: 32px;
  box-sizing: border-box;
  flex-direction: column;
  width: 50%;

  .pmx-card {
    width: 100%;

    .head {
      h4 {
        text-align: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
      }
    }

    .body {
      flex-direction: column;

      .ant-divider {
        width: calc(100% + 48px);
        transform: translateX(24px);
      }

      .payout-button {
        button {
          height: 64px;
          width: 100%;
          max-width: unset;

          span {
            font-size: 18px;
          }
        }
      }
    }

    .invite-code {
      width: 100%;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 700;
      }

      .pmx-button {
        width: fit-content;
        margin: 0;
        border-radius: 40px;

        button {
          height: 41px;
          border-radius: 40px;
          border: none !important;
          outline: none !important;
          max-width: unset;

          span {
            font-size: 16px;
            font-weight: 700;
            font-family: YekanBakh-En;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .stats {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 32px;

      .row {
        display: flex;
        width: 100%;
        padding: 12px 0px;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;

        span,
        p {
          font-size: 16px;
          font-weight: 600;
        }

        span {
          color: $gray-span;
        }
      }

      .row:first-child {
        margin-top: 0;
      }

      @include pmx-pre-lg {
        gap: 16px;

        .row {
          margin-top: 0;
        }
      }
    }
  }

  > .pmx-button {
    margin-top: 20px;

    button {
      max-width: unset;
      height: 56px;

      span {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-order-card .pmx-twofa-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: 401px;

  @include pmx-pre-lg {
    height: unset;
  }

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .error-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    svg {
      path {
        fill: $thin-red;
      }
    }

    span {
      color: $thin-red;
    }
  }

  .code {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-top: 24px;

    .code-input {
      display: flex;
      direction: ltr;
      width: 100% !important;
      box-sizing: border-box;

      &.error {
        > div > input {
          border: 1px solid $thin-red;
        }
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > input {
          background: $white;
          border: 1px solid $lightGreyBorder;
          border-radius: 10px;
          font-size: 48px;
          color: $black;
          padding: 8px;
          margin-right: 1%;

          @include pmx-pre-md {
            margin: 0 5px;
            width: 52px !important;
            height: 52px !important;
            border-radius: 5px;
            font-size: 20px;
          }
        }
      }
    }

    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;

      .resend {
        font-weight: 700;
        cursor: pointer;
        font-size: 16px;

        @include pmx-pre-lg {
          font-size: 12px;
        }
      }

      .countdown {
        display: flex;
        justify-content: start;
        align-items: center;

        .timer {
          align-self: center;
          display: inline-block;
          justify-content: center;
          align-items: center;
          margin: auto 8px;

          > div {
            display: flex;
            align-items: center;
            width: auto !important;
            height: auto !important;
            margin-top: auto;
            margin-bottom: 0;

            > svg {
              display: none;
            }

            > div {
              position: relative !important;
              height: auto !important;
              width: auto !important;
              display: inline-flex;
              color: $greyText;
              @include pmx-pre-lg {
                font-size: 12px;
              }
            }
          }
        }

        > span {
          font-size: 16px;
          color: $greyText;
          margin: auto 0;

          @include pmx-pre-lg {
            font-size: 12px;
          }
        }
      }

      .change-number {
        color: $blue;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;

        @include pmx-pre-lg {
          font-size: 12px;
        }
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-order-card .pmx-login-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 401px;

  @include pmx-pre-lg {
    min-height: unset;
  }

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .error-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    svg {
      path {
        fill: $thin-red;
      }
    }

    span {
      color: $thin-red;
    }
  }

  .ant-input {
    margin-top: 24px;
  }

   div .pmx-button {
    margin-top: 24px;

    button {
      justify-content: center;
      width: 100%;
      max-width: unset;
    }
  }


  .buttons {
    width: 100%;
    margin: 24px 0 24px;
    display: flex;
    gap: 24px;


    .pmx-button {
      width: auto;

      button {
        height: 64px;
        max-width: unset;

        @include pmx-pre-lg {
          height: 48px;
        }
      }

      button.ordercard_outlined {
        justify-content: center;

        span {
          color: $black;
          font-weight: 700;
        }
      }
    }

    .pmx-button:first-child {
      flex-grow: 5;
    }

    .pmx-button:last-child {
      flex-grow: 1;
    }
  }

}

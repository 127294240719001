@import "../../../../../core/styles/refrences/index.scss";


.pmx-order-card {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  width: 35%;
  height: auto;
  margin-inline: auto;
  background: white;
  position: relative;
  z-index: 1;

  @include pmx-pre-lg {
    width: 90%;
  }

  > div:not(.pmx-calculator) {
    @include pmx-pre-lg {
      padding: 16px !important;
    }
  }


}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: radial-gradient(
                  100% 100% at 50% 100%,
                  #0b101a 3.12%,
                  #162e66 100%
  );
  margin-top: 128px;

  hr {
    width: 100%;
    border-bottom: .5px solid #E6E8EC;
    opacity: 30%;
  }

  .main {
    width: 75%;
    margin-top: 64px;
    display: flex;
    padding-bottom: 64px;
    margin-inline: auto;
    justify-content: space-between;

    @include pmx-pre-lg {
      width: 100%;
      flex-direction: column-reverse;
      padding-bottom: unset;
      margin-top: 32px;
    }

    .about-us {
      display: flex;
      flex-direction: column;
      width: 40%;

      @include pmx-pre-lg {
        width: 90%;
        margin-inline: auto;
      }

      img {
        width: fit-content;

        @include pmx-pre-lg {
          display: none;
        }
      }

      h4 {
        color: white;
        margin-top: 32px;
        font-size: 16px;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }

      p {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff80;
        margin-top: 24px;

        @include pmx-pre-lg {
          font-size: 10px;
        }
      }

      .badges {
        display: flex;
        gap: 24px;
        padding-top: 24px;
        margin: auto 0 0;

        img {
          display: flex;
        }
      }
    }

    #mobile-logo {
      width: 24px;
      height: 32px;
      margin-right: 40px;
      margin-bottom: 16px;
      @include pmx-lg {
        display: none;
      }
    }

    .info-list {
      display: flex;
      justify-content: space-between;

      @include pmx-pre-lg {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid #e6e8ec80;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 60%;
        list-style: none;

        @include pmx-pre-lg {
          width: 90%;
        }

        h4 {
          color: white;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 32px;
          white-space: nowrap;

          @include pmx-pre-lg {
            font-size: 14px;
            margin-bottom: 16px;
          }
        }

        li {
          a {
            white-space: nowrap;
            display: flex;
            gap: 8px;
            align-items: center;
            color: #ffffff80;
            text-decoration: none;
            direction: rtl;
            font-size: 14px;
            cursor: pointer;

            @include pmx-pre-lg {
              font-size: 12px;
            }


          }

          &.address {


            a {

              @include pmx-pre-lg {
                width: 80%;
                overflow: auto;
                height: auto;
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }

  .newsletter {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: auto;
    padding: 32px 0;

    @include pmx-pre-lg {
      width: 90%;
      flex-direction: column;
    }

    p {
      display: flex;
      width: fit-content;
      font-size: 16px;
      font-weight: 700;
      color: white;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    .ant-input-affix-wrapper {
      height: 48px;
      border-radius: 63px;
      width: 20%;

      @include pmx-pre-lg {
        width: 90%;
        margin-top: 24px;
      }

      input {
        &::placeholder {
          font-size: 14px;
          font-weight: 700;
          color: $dark-grey;
          text-align: right;
        }
      }
    }
  }

  .copyright {
    display: flex;
    width: 75%;
    justify-content: space-between;
    align-items: center;
    margin-inline: auto;
    padding: 32px 0;

    @include pmx-pre-lg {
      width: 90%;
      flex-direction: column-reverse;
      gap: 24px;
      margin-bottom: 16px;
    }

    .socials {
      display: flex;
      align-items: center;
      gap: 24px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    p {
      font-size: 12px;
      font-weight: 400;
      color: white;

      span {
        color: white;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}

@import "../../core/styles/refrences/index";

body {
}

.pmx-sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background: $white;
  border-left: 1px solid #F0F0F0;
  
  &.open {
    width: 16%;
    max-width: 280px;
    transition: width 0.3s ease, max-width 0.3s ease;
  }

  &.closed {
    width: 100px;
    max-width: 100px;
    transition: width 0.3s ease, max-width 0.3s ease;

    .sidebar-head {
      padding: unset;
    }
  }

  @include pmx-pre-lg {
    display: none;
  }

  .ant-divider {
    margin: unset;
  }

  .sidebar-head {
    display: flex;
    padding-inline: 32px;
    width: calc(100% - 32px);
    justify-content: space-between;
    align-items: center;
    min-height: 96px;
    max-height: 96px;

    svg {
      margin-inline: auto;
      cursor: pointer;

      &:first-child {
        transform: translateY(-6px);
      }
    }
  }

  .sidebar-main {
    padding: 0;
    margin-top: 20px;
    width: calc(100% - 32px);
    align-self: start;
    overflow-y: scroll;
    margin-inline: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .main-head {
      flex-direction: column;
      display: flex;
      margin-bottom: 48px;
      padding-right: 7%;

      p {
        font-size: 24px;
        font-weight: 900;
      }

      span {
        font-size: 13px;
      }

      .avatar {
        width: 108px;
        height: 108px;
        background: $skyBlue;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 18px;

        svg {
          rect {
            fill: $blue;
          }

          path {
            fill: $blue;
          }

          width: 62px;
          height: 62px;
        }
      }
    }

    .menu {
      width: 100%;
      justify-content: space-evenly;
      display: flex;
      flex-direction: column;
    }
  }

  .sidebar-footer {
    p {
      text-align: center;
    }

    margin-bottom: 8%;
  }
}

.logout-modal {
  .modal-card {
    justify-content: space-between;

    p {
      font-weight: 700;
      font-size: 18px;
      text-align: center;
    }

    .buttons {
      margin-top: 24px;

      .pmx-button {
        button {
          width: 180px;
          height: 60px;
        }

        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }
}

@use "sass:selector";

$primary: radial-gradient(100% 100% at 50% 100%, #739DFF 3.13%, #3772FF 100%);
$black: #121212;
$gray: #e5e5e5;
$whiteGray: #f9fcff;
$dark-grey: #23262F;

$lightPurple: #7093ec;

$white: #ffffff;
$whiteHalfOpacity: #ffffff50;
$border: #f0f0f0;
$lightBlueBorder: #3772FF1A;
$transaction-bg: #fbfbff;

$success: #44c58f;
$danger: linear-gradient(91.95deg, #fc2a2a -14.4%, #ff4646 91.65%);
$danger-light: #fff1f1;
$danger-border: #ff4a4a;
$blue: #3772FF;
$red: #ff3d00;
$thin-red: #BD5858;
$light-red: #FFEBEB;
$delete-icon-hover-red: #B23636;
$green: #58BD7D;
$orange: #ff8a00;

$sell: linear-gradient(91.95deg, #fc2a2a -14.4%, #ff4646 91.65%);

$blueGradient: linear-gradient(
                92.36deg,
                rgba(0, 114, 255, 0.7) -46.11%,
                rgba(0, 114, 254, 0.7) 92.47%
);
$gray-span: #868686;
$extraLightBlue: #f5f7fe;
$lightRed: rgba(251, 78, 78, 0.05);

$dashboard-bg: #3364e10d;

$outlinedBg: #f9fafe;
$skyBlue: #dfeeff;
$lightBlue: #4598fe;
$extraLightBlue: #F5F8FF;
$lightGreen: #ebffeb;
$cancel: #ffeceb;
$lightOrange: #fff7eb;
$lightGray: #eeeeee;

$code-bg: #f5f5f5;
$background: #3364e40d;
$secondaryCard: rgba(51, 100, 228, 0.05);
$avatarBg: rgba(112, 147, 236, 0.2);

$purple: #8b89ff;

$yellow: #fbc718;
$dark-yellow: #F5F1DC;
$disable-primary: linear-gradient(
                92.36deg,
                rgba(0, 81, 182, 0.7) -46.11%,
                rgba(0, 114, 254, 0.7) 92.47%
);
$dark-blue: #0051b6;

$menuBorder: #80b8ff50;
$table-border: #d9dfff;
$pasteal-blue: #eaeaff;
$lightGreyBorder: #F0F0F0;
$greyText: #909090;


// Badge Colors
$badge-waiting-light: rgba(250, 246, 225, 1);
$badge-waiting: rgba(207, 174, 0, 1);

$badge-success-light: rgba(54, 178, 116, 0.05);
$badge-success: rgba(54, 178, 116, 1);

$badge-error-light: rgba(248, 242, 242, 1);
$badge-error: rgba(178, 54, 54, 1);

$badge-info-light: rgba(242, 245, 252, 1);
$badge-info: rgba(55, 114, 255, 1);

$chart-bg: #efefef;
$chart-shadow: #ff4e82;
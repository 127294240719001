@import '../../../../../core/styles/refrences/index.scss';

.pmx-landing-invite {
  position: relative;
  display: flex;
  flex-direction: column;
  background: radial-gradient(100% 100% at 50% 100%, #739DFF 3.12%, #3772FF 100%);
  width: 100%;
  height: 593px;

  @include pmx-pre-lg {
    height: 400px;
    overflow: hidden;
  }


  .pmx-navbar-home {
    margin-top: 32px;
    z-index: 1;
  }

  .pmx-mobile-navbar-home {
    margin-top: 32px;
  }

  #landing-union {
    position: absolute;
  }

  #landing-banner {
    display: none;

    @include pmx-pre-lg {
      display: initial;
      position: absolute;
      transform: translate(-50%, 70%);
      left: 50%;
      background-blend-mode: luminosity;
      opacity: 0.8;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: right;
    width: 75%;
    margin: auto;
    z-index: 1;
    background-image: url('../../../../../assets/images/invite/invite-landing.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;

    @include pmx-pre-lg {
      background-image: unset;
    }

    h2 {
      max-width: 325px;
      font-size: 40px;
      font-weight: 700;
      color: white;

      @include pmx-pre-lg {
        max-width: 200px;
        font-size: 24px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;
      color: white;
      max-width: 758px;

      @include pmx-pre-lg {
        display: none;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background-color: white;
      color: black;
      font-size: 14px;
      font-weight: 700;
      gap: 8px;
      cursor: pointer;
      height: 64px;
      width: 160px;

      @include pmx-pre-lg {
        height: 48px;
        margin-top: 16px;
      }
    }
  }
}

@import "../../core/styles/refrences/index.scss";

.pmx-page {

  h1 {
    margin-bottom: 32px;
    font-size: 40px;
    font-weight: 900 !important;
  }

  > .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
}

@import "core/styles/refrences/index";

.order-details-modal {
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  top: 10%;

  .pmx-badge {
    margin: 0 auto 0;
  }

  .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    width: 100%;
    margin-top: 24px;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .long-text {
        width: 180px;
        text-wrap: nowrap;
        max-width: 290px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      span {
        font-size: 14px;
        color: $gray-span;
      }

      p {
        width: max-content;
        font-size: 14px;
      }

      .copy {
        display: flex;
        align-items: center;
        
        svg {
          margin-right: 8px;
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      p.en {
        font-family: YekanBakh-En !important;
      }

    }

    .row:first-child {
      margin-top: 0;
    }
  }

  .pmx-button {
    margin-top: 32px;

    button {
      max-width: unset;
      height: 64px;

      span {
        font-weight: 700;
        font-size: 18px;
        @include pmx-pre-lg {
          font-size: 16px;
        }
      }
    }
  }

  .fa {
    direction: rtl;
  }
}

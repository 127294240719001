@import "core/styles/refrences/index";

.pmx-authentication .silver-auth-form,
.pmx-authentication .gold-auth-form {
  width: 48%;
  padding: 20px;

  .head {
    h4 {
      font-size: 22px;
      font-weight: 700;
      color: black;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .pmx-button {
      width: 100%;
      margin-top: 32px;

      button {
        height: 56px;
        max-width: unset;

        span {
        }
      }
    }

    .row {
      display: flex;
      margin-top: 16px;
      box-sizing: border-box;
      width: 100%;

      .rmdp-container {
        width: 100%;

        .pmx-input {
          .adornments {
            svg {
              path {
                fill: black;
              }
            }
          }
        }
      }

      .email {
        input {
          font-family: YekanBakh-En !important;
        }
      }

      .pmx-input {
        height: 56px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        max-width: unset;

        input {
        }
      }

      textarea {
        background-color: $outlinedBg;
        width: 100%;
        font-size: 16px;
        outline: none;
        border: 0;
        background: none;
        border: 1px solid $border;
        border-radius: 8px;
        height: 56px;
        resize: none;
        font-family: YekanBakh;
        padding: 13px 12px 13px;
        @include pmx-pre-lg {
          font-size: 12px;
        }

        &::placeholder {
          opacity: 0.3;
        }
      }

      .col {
        box-sizing: border-box;
        width: 50%;

        .pmx-input {
          width: 100%;
        }
      }

      .col:last-child {
        margin-right: 16px;
      }
    }

    .row:first-child {
      margin-top: 0;
    }
  }
}

.pmx-authentication .gold-auth-form {
  height: max-content;

  .body {
    flex-direction: column;
  }

  .body > form > p {
    font-size: 16px;
    color: black;
    font-weight: 500;
  }

  .body > .bg {
    display: flex;
    width: 100%;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    background: $dark-yellow;
    border-radius: 8px;

    img {
      padding: 5%;
    }

  }
}

.pmx-authentication > .pmx-card:last-child {
  margin-top: 32px;
}

.pmx-authentication .pmx-card.completed {
  width: 48%;
  padding: 24px;
  height: fit-content;

  .body {
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    border-radius: 15px;

    .bg {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #EDFFF4;

      img {
        padding: 5%;
      }
    }

    h4 {
      color: #36B274;
      font-size: 20px;
      font-weight: 700;
      margin-top: 24px;
      text-align: right;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      p {
        font-size: 14px;
        color: black;
        font-weight: 700;
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-authentication .silver-auth-form,
  .pmx-authentication .gold-auth-form {
    width: 100%;
  }

  .pmx-authentication .pmx-card.completed {
    width: 100%;
  }
  .pmx-authentication .silver-auth-form {
    padding: 16px;
    margin-top: 24px;
    width: 100%;

    .head {
      h4 {
        font-size: 16px;
      }
    }

    form {

      .row {
        margin-top: 16px;

        .pmx-input {
          height: 48px;
        }
      }

      p {
      }
    }
  }
  .pmx-authentication {
    .gold-auth-form {
      margin-top: 24px;
      padding: 24px;

      .body {
        form {
          margin-top: 24px;

          p {
            font-size: 12px;
          }

          .pmx-button {
            margin-top: 40px;
          }
        }
      }

      .head {
        h4 {
          font-size: 16px;
        }
      }
    }
  }
  .pmx-authentication {
    .silver-auth-form,
    .gold-auth-form {
      form .pmx-button {
        margin-top: 32px;

        button {
          height: 48px;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@import "core/styles/refrences/index";

nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
  background: white;
  max-height: 97px;
  z-index: 100;

  .navbar-welcome {
    @include pmx-pre-lg {
      display: none;
    }

    display: flex;
    flex-direction: column;
    gap: 8px;
    h4 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    span {
      color: #777e90;
      font-size: 14px;
    }
  }

  @include pmx-pre-lg {
    padding: 32px 24px 24px;
  }

  h1 {
    font-size: 36px;
    font-weight: 900 !important;

    @include pmx-pre-lg {
      font-size: 20px;
    }
  }

  .pmx-navbar-dropdown {
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    background: #fff;
    padding: 12px;
    width: 180px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    @include pmx-pre-lg {
      height: 30px;
      padding: 3px;
      max-width: 138px;
    }

    > img {
      position: relative;
      z-index: 13;

      @include pmx-pre-lg {
        width: 24px;
        height: 24px;
      }
    }

    .username {
      font-size: 18px;
      font-weight: 700;
      z-index: 13;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }

    > svg {
      position: relative;
      z-index: 13;

      @include pmx-pre-lg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% - 48px);
    left: 0;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: $white;
    margin: 0;
    z-index: 11;
    padding-right: 0;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));

    @include pmx-pre-lg {
      top: calc(100% - 30px);
    }

    a {
      width: 100%;
      display: flex;
    }

    li {
      background: $white;
      padding: 12px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      height: 48px;

      @include pmx-pre-lg {
        padding: 8px;
        height: 32px;
      }

      span {
        font-size: 16px;
        margin-right: 16px;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 12px;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        vertical-align: center;
        margin: auto 0;

        @include pmx-pre-lg {
          width: 16px;
          height: 16px;
        }


      }
    }

    li:first-child {
      margin-top: 40px;

      @include pmx-pre-lg {
        margin-top: 30px;
      }
    }

    li:hover {
      background: $extraLightBlue;
    }

    li.red {
      span {
        color: $red;
      }

      svg {
        path {
          fill: $red;
        }
      }
    }

    li.red:hover {
      background: $lightRed;
    }
  }
}
.logout-modal.pmx-modal {
  padding: 32px 24px 24px 24px;
  .buttons {
    margin-top: 32px;
    display: flex !important;

    .pmx-button {
      width: 48%;
      height: 64px;
      button {
      }
    }
  }
}

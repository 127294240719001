@import "../../core/styles/refrences/index";

.pmx-dropdown {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-width: 375px;
  margin: 0 auto;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid $border;
  background: $outlinedBg;
  padding: 20px 24px;
  box-sizing: border-box;
  height: 64px;

  .dropdown-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    img {
      width: 13px;
    }

    img,
    svg,
    .anticon {
      width: 21px;
      height: 21px;

      path {
        fill: $lightPurple;
      }
    }

    a {
      color: $black !important;
      display: flex;
      align-items: center;
      font-size: 16px;

      .placeholder {
        color: $lightPurple;
      }
    }

    .anticon {
      width: 13px;
      cursor: pointer;

      svg {
        fill: $primary;
      }
    }
  }

  .dropdown-items {
    position: absolute;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
    border-radius: 20px;
    background: $white;
    border: 1px solid $border;
    top: 102%;
    left: 0%;
    z-index: 999;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 8px;
    width: 100%;
    max-height: 263px;
    list-style: none;
    box-sizing: border-box;
    overflow-y: auto;

    li {
      padding: 16px;
      width: 100%;
      height: 56px;
      box-sizing: border-box;
      background: $outlinedBg;
      color: $black;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 8px;
      border-radius: 15px;
      padding: 16px;

      &:hover {
        background: $extraLightBlue;
      }

      svg,
      img,
      .anticon {
        margin-right: 16px;
        width: 21px;
        height: 21px;
      }

      > span:last-child {
        display: flex;
        align-items: center;
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    li:first-child {
      margin-top: 0;
    }

    .ant-divider {
      margin-bottom: 0;
      margin-top: 0;
    }

    div:hover {
      background: $outlinedBg;
    }
  }
}

@import "core/styles/refrences/index";

.pmx-referral {
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: auto 0;
  width: 100%;
  padding: 32px;

  .rules {
    .body {
      flex-direction: column;
      align-items: start;
      justify-content: start;
    }

    .agree {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: start;
      margin-top: 20px;

      input {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .pmx-button {
      margin-top: 32px;

      button {
        width: 217px;
        height: 64px;

        span {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }

  .main-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }

  //.pmx-referral
  @include pmx-pre-lg {
    .rules {
      margin-bottom: 80px;

      .agree {
        margin-top: 0px;

        span {
          font-size: 14px;
          margin-right: 8px;
        }
      }

      .pmx-button {
        button {
          margin: auto;

          span {
            font-size: 12px;
          }
        }
      }
    }

    .main-row {
      flex-direction: column;

      .congrats-card {
        width: 100%;
        margin-bottom: 24px;
        padding: 24px;

        .body p {
          font-size: 12px;
        }
      }

      .invite-friends {
        width: 100%;
        margin: auto;

        .pmx-card {
          padding: 24px;

          .head {
            h4 {
              font-size: 16px;
            }
          }

          .body .stats .row {
            span {
              font-size: 14px;
            }

            p {
              font-size: 12px;

              span {
                font-size: 12px;
              }
            }
          }

          .invite-code {
            margin-top: 24px;
            align-items: baseline;

            span {
              font-size: 14px;
            }

            .pmx-button {
              width: 167px;

              button {
                height: 48px;
                border-radius: 15px;

                svg {
                  width: 20px;
                  height: 20px;
                }

                span {
                  @include pmx-size(font-size, 18px, 16px);
                }
              }
            }
          }
        }
      }
    }
  }
}

.referral-payout-modal {
  padding: 32px 24px;
  top: 25%;

  .pmx-dropdown {
    margin-top: 16px;
  }

  .pmx-input {
    margin-top: 24px;
    max-width: unset;
    width: 100%;
    height: 64px;

    input {
    }

    .adornments {
      > span {
        color: $lightPurple;
        cursor: pointer;
      }
    }
  }

  > span {
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    color: #909090;
    width: fit-content;
    font-weight: 600;
  }

  .buttons {
    display: flex;
    margin-top: 24px;
    gap: 16px;

    .pmx-button {
      width: 60%;
      button {
        height: 64px;

        span {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .pmx-button:last-child {
      width: 40%;
      button {
        span {
          color: black;
        }
      }
    }
  }

  @include pmx-pre-lg {
    padding: 24px 16px 16px;

    .pmx-dropdown {
      min-width: 0px;
      width: 100%;
      height: 48px;

      .dropdown-selector {
        .anticon {
          display: flex;
          align-items: center;

          svg {
            width: 10px;
            height: 10px;
          }
        }
      }

      a .placeholder {
        font-size: 12px;
      }
    }

    .pmx-input {
      font-size: 12px;
      height: 48px;
      padding: 16px;
    }

    .buttons {
      .pmx-button {
        button {
          height: 48px;

          span {
            font-size: 12px;
          }
        }
      }
    }

    .input-hint {
      margin-top: 8px;
      font-size: 12px;
      margin-inline: auto;
    }
  }
}

@include pmx-pre-lg {
  .referral-payout-modal {
    padding: 24px 16px 16px;
  }
}

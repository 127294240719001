@import "../../../../../core/styles/refrences/index.scss";

.pmx-faq-landing {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: radial-gradient(
    100% 100% at 50% 100%,
    #739dff 3.12%,
    #3772ff 100%
  );
  height: 480px;

  @include pmx-pre-lg {
     height: 400px;
  }

  .pmx-mobile-navbar-home {
    margin-top: 32px;
  }

  .pmx-navbar-home {
    margin-top: 32px;
    z-index: 1;
  }

  .search-box {
    display: flex;
    flex-direction: column;
    font-size: 32px;
    font-weight: 700;
    color: white;
    width: 30%;
    margin: auto;
    z-index: 1;

    @include pmx-pre-lg {
      width: 90%;
    }

    h2 {
      margin-inline: auto;
      font-size: 32px;
      font-weight: 700;

      @include pmx-pre-lg {
        font-size: 20px;
      }
    }

    .ant-input-affix-wrapper {
      margin-top: 32px;
      height: 54px;
      border-radius: 16px;

      input {
        font-size: 14px;
        &::placeholder {
          color: #777e90;
        }
      }
    }
  }

  #banner {
    filter: opacity(0.5);
    position: absolute;
    z-index: 0;
    height: auto;
    margin-inline: auto;
    width: 100%;
    height: 100%;
    aspect-ratio: 2/1;
  }
}

@import "../../core/styles/refrences/index.scss";

.pmx-badge {
  max-width: 180px;
  width: max-content;

  div {
    border: 0;
    outline: 0;
    height: 35px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    direction: ltr;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }

    &.success {
      background: $badge-success-light;

      span {
        color: $badge-success !important;
      }
    }

    &.primary {
      background: $primary;
      color: $white;
    }

    &.secondary {
      background: $outlinedBg;
      color: $blue;
    }

    &.error {
      background: $badge-error-light;

      span {
        color: $badge-error !important;
      }
    }

    &.waiting {
      background: $badge-waiting-light !important;

      span {
        color: $badge-waiting !important;
      }
    }

    &.info {
      background: $badge-info-light;

      span {
        color: $badge-info !important;
      }
    }

    img {
      margin-left: 5px;
    }

    span {
      font-weight: 700;
      font-size: 12px;

      @include pmx-pre-lg {
        font-size: 10px;
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-badge {
    > div {
      height: 29px;
    }
  }
}

@import "core/styles/refrences/index";

.pmx-bank-account {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 32px;

  .cards-list {
    .body {
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      gap: 16px;
      width: 100%;
      box-sizing: border-box;
    }

    .card {
      box-sizing: border-box;
      display: flex;
      justify-content: start;
      background: radial-gradient(
        100% 100% at 50% 100%,
        #739dff 3.13%,
        #3772ff 100%
      );
      padding: 16px;
      position: relative;
      border-radius: 8px;
      flex-direction: column;
      width: 100%;
      max-width: 333px;
      min-height: 200px;
      margin-inline: auto;
      overflow: hidden;

      .icons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .delete {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: $white;
          box-sizing: border-box;
          padding: 8px;
          top: 16px;
          left: 16px;
          width: 32px;
          height: 32px;
          border-radius: 4px;
          z-index: 20;
        }

        .logo {
          width: 24px;
          height: 24px;
          user-select: none;
          -webkit-user-drag: none;
        }
      }

      .bg {
        display: flex;
        position: absolute;
        width: 60%;
        height: 100%;
        top: 0px;
        left: -72px;
        filter: brightness(0.2) invert(1) opacity(0.1);
        z-index: 1;
        align-items: center;

        img {
          width: 100%;
          user-select: none;
          -webkit-user-drag: none;
        }
      }

      > div:last-child {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        span {
          color: $white;
          font-size: 16px;
        }
      }

      > span {
        font-size: 20px;
        font-weight: 700;
        color: $white;
        margin-top: 19px;
      }

      > p {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        font-size: 28px;
        font-weight: 600;
        color: $white;
        margin-top: 8px;
        width: 100%;
        box-sizing: border-box;
        margin-top: auto;

        z-index: 5;

        span {
          margin-left: 2%;
        }

        span:first-child {
          margin-left: 0;
        }
      }

      @include pmx-pre-lg {
      }
    }

    .pre-navigation {
      backdrop-filter: blur(10px);
      background: $whiteHalfOpacity;
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      right: 20px;
      top: calc(42% - 20px);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;

      @include pmx-pre-lg {
        right: 8px;
        width: 32px;
        height: 32px;
        top: calc(45% - 16px);

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .next-navigation {
      backdrop-filter: blur(10px);
      background: $whiteHalfOpacity;
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      cursor: pointer;
      left: 20px;
      top: calc(42% - 20px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      transform: rotate(180deg);

      @include pmx-pre-lg {
        left: 8px;
        width: 32px;
        height: 32px;
        top: calc(45% - 16px);

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .add-bank-account {
    margin: 48px auto 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .pmx-button {
      width: max-content;

      button {
        border-radius: 8px;

        height: 64px;
        width: 187px;

        span {
          font-weight: 700;
          font-size: 20px;
          @include pmx-pre-lg {
            font-size: 16px;
          }
        }

        @include pmx-pre-lg {
          width: 142;
          height: 48px;
        }
      }
    }

    @include pmx-pre-lg {
      margin: 40px auto 16px;
      .pmx-button {
        button {
          border-radius: 15px;
        }
      }
    }

    > span:last-child {
      font-size: 20px;
      margin: auto;
      margin-top: 24px;

      @include pmx-pre-lg {
        font-size: 16px;
        margin-top: 16px;
      }
    }
  }

  font-size: 20px;

  @include pmx-pre-lg {
    margin: auto;
    padding: 16px;
    padding-bottom: 140px;

    .cards-list {
      padding: 16px;
    }
  }
}

.add-cart-modal {
  padding: 24px;
  flex-direction: column;
  align-items: start;

  p {
    color: #909090;
    font-size: 16px;
  }

  .pmx-input {
    padding: 8px 15px;
    margin: 0;
    max-width: unset;
    width: 100%;
    height: 64px;
    border-radius: 16px;
    margin-top: 16px;
  }

  > span {
    display: flex;
    gap: 4px;
    margin-top: 8px;
    color: #d4781c;
    font-size: 16px;
  }

  .buttons {
    display: flex;
    gap: 16px;
    width: 100%;

    .pmx-button {
      margin: 12px 0;

      button {
        height: 64px;
        max-width: unset;
        width: 100%;

        span {
          font-size: 16px;
          font-weight: 600;
          @include pmx-pre-lg {
            font-size: 14px;
          }
        }

        @include pmx-pre-lg {
          height: 48px;
          border-radius: 12px;
        }
      }

      &:last-child {
        width: fit-content;

        button {
          span {
            color: black;
          }
        }
      }
    }
  }

  @include pmx-pre-lg {
    margin: auto;
    padding: 16px;
    min-width: 311px;
    min-height: 216px;
  }
}

.pmx-modal h4 {
  @include pmx-pre-lg {
    font-size: 14px;
  }
}

.remove-cart-modal {
  p {
    color: #909090;
    font-size: 16px;
  }

  .card-info {
    background-color: #f5f8ff;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    .name-logo {
      display: flex;
      gap: 4px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;

    .pmx-button {
      button {
        width: 100%;
        max-width: 265px;
        height: 64px;

        span {
          font-size: 16px;
          font-weight: 600;
          @include pmx-pre-lg {
            font-size: 14px;
          }
        }
      }
    }

    .pmx-button:last-child {
      button {
        span {
          color: black;
          margin: auto;
        }
      }
    }
  }

  @include pmx-pre-lg {
    left: calc(50% - 173px);
    margin: auto;
    // padding: 16px;
    min-width: 311px;
    min-height: 131px;
  }
}

.pmx-bank-account .swiper-pagination-fraction,
.pmx-bank-account .swiper-pagination-custom,
.pmx-bank-account .swiper-horizontal > .swiper-pagination-bullets,
.pmx-bank-account .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex !important;
  justify-content: center !important;
  margin: 26px auto;

  @include pmx-pre-lg {
    margin: 16px auto;
  }
}

@include pmx-pre-lg {
  .remove-cart-modal {
    left: calc(50% - 170px);
  }
}

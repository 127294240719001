@import "core/styles/refrences/index";

.price-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: $white;
  border: 1px solid $border !important;
  padding: 14px;
  border-radius: 20px;
  box-sizing: border-box;
  white-space: nowrap;

  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      align-items: end;
    }

    .currency-info {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;

      img {
        margin-left: 8px;
        width: 32px;
        height: 32px;
      }

      p {
        display: flex;
        flex-direction: column;

        span {
          &:first-child {
            font-size: 14px;
            font-weight: 600;
          }

          &:last-child {
            color: #b3b3b3;
            text-align: right;
            font-size: 10px;
          }
        }
      }
    }

    .price-info {
      display: flex;
      flex-direction: column;
      .buy-rate {
        color: $green;
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
      }
      .rate-value {
        text-align: right;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .value {
      font-size: 24px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      align-items: end;

      span {
        font-size: 10px;
        font-weight: 400;
      }
    }

    // button {
    //   border: none;
    //   outline: none;
    //   background: $yellow;
    //   display: flex;
    //   justify-content: start;
    //   align-items: center;
    //   padding: 9px 8px;
    //   border-radius: 10px;
    //   height: 32px;
    //   width: 70px;
    //   cursor: pointer;

    //   span {
    //     margin-right: 8px;
    //     font-size: 12px;
    //     font-weight: 700;
    //   }
    // }

    // .percent {
    //   font-size: 16px;
    //   font-weight: 500;
    //   color: $success;

    //   span {
    //     font-size: 12px;
    //     font-weight: 400;
    //   }
    // }

    .percent.negative {
      color: $red;
    }
  }
}

@include pmx-pre-lg {
  .price-card {
    padding: 12px;
    border-radius: 15px;

    .row {
      p,
      span {
        font-size: 12px;
      }

      p {
        img {
          width: 20px;
          height: 20px;
          margin-left: 7px;
        }
      }

      // button {
      //   padding: 6px 8px;
      //   font-size: 10px;
      //   height: 24px;
      //   width: 54px;

      //   svg {
      //     width: 12px;
      //     height: 10px;
      //   }
      // }

      .value {
        font-size: 14px;

        span {
          font-size: 10px;
        }
      }
    }
  }
}

@import "../../../../core/styles/refrences/index.scss";

.pmx-terms-and-conditions {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-inline: auto;
  margin-top: 48px;

  @include pmx-pre-lg {
    width: 90%;
    margin-top: 32px;
  }

  div {
    margin-top: 32px;
    @include pmx-pre-lg {
        margin-top: 24px;
    }
    h3 {
      font-size: 32px;
      font-weight: 700;

      @include pmx-pre-lg {
        font-size: 20px;
      }
    }

    p {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 700;

      @include pmx-pre-lg {
        font-size: 12px;
        margin-top: 16px;
      }
    }
  }
}

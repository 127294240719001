@import "../../../core/styles/refrences/index.scss";

.pmx-faq {
  .pmx-footer {
    margin-top: 120px;

    @include pmx-pre-lg {
      margin-top: 40px;
    }
  }
}

@import "core/styles/refrences/index";

.pmx-authentication {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 32px;

  > .content {
    flex-direction: row;
    justify-content: space-between;
  }

  .pmx-card.completed .body > div {
    white-space: nowrap;
  }

  .pmx-card.helper-side {
    width: 48%;
    .body {
      flex-direction: column;

      .medals {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin-top: 16px;
        height: auto;

        .ant-divider {
          min-width: unset;
          transform: translateY(-32px);

          @include pmx-pre-lg {
          display: none !important;
          }
        }

        @include pmx-pre-lg {
          gap: unset;
          align-items: flex-end;
        }

        .medal-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          height: 100%;

          img {
            margin: auto;
          }
        }
      }

      .pros {
        margin-top: 24px;
        padding: 0px;
        border: none;

        .body {
          display: flex;
          flex-direction: column;

          .head-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div:last-child {
              display: flex;
              gap: 8px;
              align-items: center;

              span {
                font-size: 16px;
                font-weight: 700;
                color: #777e90;

                @include pmx-pre-lg {
                  font-size: 14px;
                }
              }

              p {
                color: black;
                font-size: 14px;
                font-weight: 700;

                @include pmx-pre-lg {
                  font-size: 12px;
                }
              }
            }
          }

          > p {
            margin-top: 24px;
            font-size: 16px;
            @include pmx-pre-lg {
              font-size: 12px;
            }
          }
        }

        h4 {
          font-size: 20px;
          font-weight: 700;
          color: black;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: black;
        }
      }

      .notes {
        margin-top: 32px;
        padding: 24px;

        ul,
        li {
          margin: 0;
          padding: 0;
          list-style: none;
        }

        ul {
          display: flex;
          flex-direction: column;
          align-items: start;
          margin-top: 24px;

          li {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-top: 24px;

            p {
              margin-right: 12px;
              font-size: 16px;
              text-align: justify;
              width: 100%;

              @include pmx-pre-lg {
                font-size: 12px;
              }
            }
          }

          li:first-child {
            margin-top: 0;
          }
        }
      }
    }

    @include pmx-pre-lg {
      width: 100%;
      margin-top: 24px;
    }
  }
}

@include pmx-pre-lg {
  .pmx-authentication {
    flex-direction: column;
    padding: 0 24px 140px;

    > .content {
      flex-direction: column;
    }

    .helper-side {
      order: 1;
      width: 100%;
      margin-right: 0;

      .pmx-card.completed {
        border-radius: 20px;
        padding: 8px;
        height: auto;

        .body {
          border-radius: 15px;
          padding: 24px;
          flex-direction: column;
          align-items: center;
          justify-content: start;

          img {
            width: 91px;
            height: 128px;
            top: 0;
            margin-top: -79px;
          }

          > div {
            margin-right: 0;
            margin-top: 16px;
            align-items: center;

            h4 {
              font-size: 16px;
              text-align: center;
            }

            p {
              font-size: 12px;
              margin-top: 8px;
              text-align: center;
            }
          }
        }
      }

      .medals {
        width: 100%;
        box-sizing: border-box;
        margin-right: 0;
        gap: unset;

        img {
          width: 30%;
        }

        img:first-child {
          margin-right: 0;
        }
      }

      .pros,
      .notes {
        padding: 24px;
        margin-top: 24px;
      }

      .pros {
        .body {
          .head-row {
            h4 {
              font-size: 16px;
            }

            > div:last-child {
              span {
                font-size: 14px;
              }

              p {
                font-size: 14px;
              }
            }
          }

          p {
            font-size: 12px;
          }
        }
      }

      .notes {
        .body {
          li {
            margin-top: 24px;

            svg {
              width: 16px;
              height: 16px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }

    .pmx-card {
      order: 2;
      width: 100%;
    }
  }
}

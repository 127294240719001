@import "../../../../../core/styles/refrences/index.scss";

.pmx-help {
  display: flex;
  margin-inline: auto;
  justify-content: center;
  align-items: start;
  width: 75%;
  height: 344px;
  margin-top: 120px;
  gap: 32px;

  @include pmx-pre-lg {
    width: 90%;
    flex-direction: column;
    height: auto;
    margin-top: 40px;
    gap: 16px;
  }

  .pmx-faq-card {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    padding: 24px;

    @include pmx-pre-lg {
      padding: 8px;
      width: calc(100% - 16px);
      flex-direction: row;
      gap: 16px;
    }

    .bg {
      display: flex;
      background: #e5f2ff;
      border-radius: 8px;
      width: 100%;
      height: auto;
      box-sizing: border-box;

      @include pmx-pre-lg {
        width: 48px;
        height: 48px;
        padding: 12px;
      }

      img {
        padding: 5%;
        margin-inline: auto;

        @include pmx-pre-lg {
          padding: unset;
          margin: auto;
          width: 24px;
          width: 24px;
        }
      }
    }

    h4 {
      font-size: 32px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;

      @include pmx-pre-lg {
        width: 100%;
        font-size: 16px;
        margin-top: unset;
      }

      svg {
        cursor: pointer;
      }
    }

    p {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #777e90;

      @include pmx-pre-lg {
        display: none;
      }
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 32px;

    @include pmx-pre-lg {
      width: 100%;
      gap: 16px;
    }

    .pmx-referral-card, .pmx-instructions-card {
      display: flex;
      flex-direction: row;
      border-radius: 16px;
      border: 1px solid #f0f0f0;
      padding: 24px;

      @include pmx-pre-lg {
        padding: 8px;
        width: calc(100% - 16px);
      }

      .bg {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5dcdc;
        border-radius: 8px;
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        padding: 24px;

        @include pmx-pre-lg {
          width: 48px;
          height: 48px;
          padding: 12px;
        }

        img {
          width: auto;
          height: auto;
          margin: auto;

          @include pmx-pre-lg {
            width: auto;
            height: 24px;
          }
        }
      }

      h4 {
        font-size: 32px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 24px;

        @include pmx-pre-lg {
          font-size: 16px;
          margin-right: 16px;
        }


      }

      svg {
        cursor: pointer;
        margin: auto auto auto 0;
        width: 32px;
        height: 32px;
      }
    }

    .pmx-instructions-card {
      .bg {
        background: $dark-yellow;
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-payment-method-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 300px;

  @include pmx-pre-lg {
    padding: 0 16px;
  }

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .account-id-input::placeholder {
    text-align: right;
  }

  .methods {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;

    .method-item {
      background: $extraLightBlue;
      border: 1px solid $lightGreyBorder;
      padding: 24px;
      gap: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      cursor: pointer;
      box-sizing: border-box;
      height: 137px;

      @include pmx-pre-lg {
        height: 100px;
        padding: 16px;
      }

      > div p, > div span {
        @include pmx-pre-lg {
          font-size: 14px;
        }
      }

      &.disabled {
        opacity: .5;
      }

      &.active {
        background: $primary;

        > div {
          span, p {
            color: $white;
          }
        }

        .inner-circle {
          background: $blue;
        }
      }

      > div:first-child {
        width: 70%;

        @include pmx-pre-lg {
          width: 90%;
        }

        span {
          font-size: 20px;
          font-weight: 700;

          @include pmx-pre-lg {
            font-size: 14px;
          }
        }

        p {
          font-size: 16px;

          @include pmx-pre-lg {
            font-size: 12px;
          }
        }
      }

      .circle-badge {
        width: 20%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        width: 40px;
        height: 40px;
        border-radius: 25px;

        @include pmx-pre-lg {
          width: 24px;
          height: 24px;
        }


        .inner-circle {
          width: 16px;
          height: 16px;
          border-radius: 50px;

          @include pmx-pre-lg {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }


}

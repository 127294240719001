@import "../../../../../core/styles/refrences/index.scss";

.congrats-card {
  padding: 24px 32px 32px;
  width: 50%;
  flex: 1 1 auto;

  .body {
    flex-direction: column;
    align-items: center;
    height: 100%;

    .images {
      width: 100%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10%;
      background-color: #e7ffeb;
      border-radius: 8px;

      img {
        width: 30%;
        height: auto;
        padding: 5%;

        @include pmx-pre-lg {
          width: 30%;
        }
      }
    }

    p {
      max-width: 623px;
      font-size: 16px;
      color: black;
      font-weight: 700;
      margin-top: 20px;

      h4 {
        color: #36B274;
        font-size: 20px;
      }
    }
  }
}

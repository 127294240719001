@import "core/styles/refrences/index";

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset;
  }

  40%,
  100% {
    background-position: 140px + $avatar-offset;
  }
}

.table-data-skeleton {
  @include background-gradient;
  animation: shine-avatar $animation-duration infinite linear;
  border-radius: 8px;
  width: 40px;
  height: 10px;
}

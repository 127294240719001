@import "core/styles/refrences/index.scss";

.pmx-more-income {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  width: 75%;
  margin-inline: auto;

  @include pmx-pre-lg {
    width: 90%;
    max-height: 673px;
    margin-bottom: 256px;
    overflow-x: clip;
  }

  main {
    background: radial-gradient(
                    100% 100% at 50% 100%,
                    #739dff 3.12%,
                    #3772ff 100%
    );
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 711px;

    #more-union {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    #coin1 {
      position: absolute;
      top: 10%;
      left: 10%;

      @include pmx-pre-lg {
        left: -25%;
      }
    }

    #coin2 {
      position: absolute;
      top: 10%;
      right: 10%;

      @include pmx-pre-lg {
        right: -25%;
        top: 25%;
      }
    }

    #coin3 {
      position: absolute;
      bottom: 0;
      left: 0;
      filter: blur(4px);
      z-index: 2;

      @include pmx-pre-lg {
        display: none;
      }
    }

    #coin4 {
      position: absolute;
      bottom: 0;
      right: 0;
      filter: blur(4px);

      @include pmx-pre-lg {
        bottom: 0;
        left: -5%;
        right: unset;
        transform: scaleX(-1);
        z-index: 4;
      }
    }

    h3 {
      font-size: 32px;
      font-weight: 700;
      color: white;
      text-align: center;
      margin-top: 32px;
      z-index: 1;

      @include pmx-pre-lg {
        font-size: 20px;
        margin-top: 16px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: white;
      text-align: center;
      margin-top: 32px;
      z-index: 1;

      @include pmx-pre-lg {
        font-size: 12px;
        margin-top: 16px;
      }
    }

    .buttons {
      display: flex;
      gap: 24px;
      z-index: 1;
      justify-content: center;
      margin-top: 32px;

      @include pmx-pre-lg {
        margin-top: 16px;
      }

      a {
        &:first-of-type {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background-color: white;
          color: #3772ff;
          font-size: 14px;
          font-weight: 700;
          gap: 8px;
          padding: 8px 16px;
          cursor: pointer;
          height: 48px;
          width: 160px;

          @include pmx-pre-lg {
            height: 40px;
            padding: 4px 8px;
            width: unset;
          }
        }

        &:nth-of-type(2) {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: white;
        }
      }
    }

    .referral-table-container {
      border: 1px solid #f0f0f0;
      background-color: white;
      border-radius: 8px;
      width: 85%;
      margin-inline: auto;
      margin-top: 32px;
      z-index: 1;
      padding-bottom: 32px;

      @include pmx-pre-lg {
        display: none;
      }

      table {
        width: 95%;
        margin-inline: auto;
        border-collapse: collapse;

        thead {
          tr {
            th {
              color: #777e90;
              padding: 32px;
              font-size: 14px;
              font-weight: 500;
            }

            border-bottom: 1px solid #f0f0f0;
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #f0f0f0;

            &:last-child {
              border: none;
            }

            td {
              padding: 32px;
              font-weight: 700;
              text-align: center;

              &:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &:last-child {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
              }
            }
          }
        }
      }

      .your-position {
        width: 90%;
        margin-inline: auto;
        display: flex;
        align-items: center;
        border: 1px solid #3772ff;
        height: 48px;
        font-size: 14px;
        font-weight: 700;
        color: #3772ff;
        border-radius: 8px;
        padding-right: 16px;
      }
    }

    .referral-mobile-container {
      display: none;
      border: 1px solid #f0f0f0;

      @include pmx-pre-lg {
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 80%;
        margin: 0 auto;
        border-radius: 8px;
        padding: 16px;
        margin-top: 16px;
        z-index: 3;
      }

      .referral-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-bottom: 1px solid #F0F0F0;
        padding: 16px 0;
        background-color: white;

        .row {
          display: flex;
          justify-content: space-between;

          &:first-child {
            .column {
              display: flex;
              flex-direction: column;

              span {
                &:first-child {
                  font-size: 14px;
                  font-weight: 700;
                }

                &:last-child {
                  font-size: 12px;
                  font-weight: 500;
                  color: #96A0B5;
                }
              }
            }

            img {
              height: 32px;
            }
          }

          &:last-child {
            .column {
              display: flex;
              flex-direction: column;

              span {
                &:first-child {
                  font-size: 12px;
                  font-weight: 700;
                }

                &:last-child {
                  font-size: 10px;
                  font-weight: 700;
                  color: #96A0B5;
                }
              }

              &:first-child {
                align-items: flex-start;
              }

              &:last-child {
                align-items: flex-end;
              }
            }
          }
        }
      }

      .your-position {
        width: 90%;
        margin-inline: auto;
        display: flex;
        align-items: center;
        border: 1px solid #3772ff;
        height: 48px;
        font-size: 14px;
        font-weight: 700;
        color: #3772ff;
        border-radius: 8px;
        justify-content: center;
        margin-top: 16px;
      }
    }
  }
}

@import "../../../../../../../../core/styles/refrences/index.scss";

.pmx-pm-popup-modal {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  max-width: 600px;
  gap: 16px;

  @include pmx-pre-lg {
    max-width: 400px;
  }

  .title {
    font-size: 24px;
    color: $blue;
    font-weight: 700;

    @include pmx-pre-lg {
      font-size: 18px;
    }

    span {
      color: #FF373A;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      &:first-child {
        font-size: 20px;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }

      &:last-child {
        font-weight: 500;
        font-size: 18px;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }
    }
  }

  .buttons {
    display: flex;
    box-sizing: border-box;
    gap: 16px;

    .pmx-button {
      max-width: unset;

      &:first-child {
        width: 75%;
      }

      &:last-child {
        width: 25%;
      }

      button {
        max-width: unset;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 64px;

        @include pmx-pre-lg {
          height: 48px;
        }
      }
    }
  }

  img {
    background: $blue;
    border-radius: 8px;
  }
}
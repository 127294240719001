@import '../../../../../core/styles/refrences/index.scss';

.pmx-rates {
  display: flex;
  width: 75%;
  gap: 16px;
  margin-inline: auto;
  justify-content: space-between;
  margin-top: 120px;

  @include pmx-pre-lg {
    width: 90%;
    flex-direction: column;
    margin-top: 40px;
  }

  .rate {
    display: flex;
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    padding: 16px;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include pmx-pre-lg {
      width: calc(100% - 32px);
    }

    .currency-info {
      display: flex;
      gap: 8px;

      img {
        width: 48px;
        height: 48px;
      }

      .col {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: right;

        span {
          &:first-child {
            font-size: 14px;
            font-weight: 700;
            color: black;
          }

          &:last-child {
            font-size: 12px;
            font-weight: 700;
            color: #b3b3b3;
          }
        }
      }
    }

    .currency-rates {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 14px;
      font-weight: 700;
      text-align: right;

      div {
        display: flex;
        gap: 4px;
        justify-content: space-between;

        .buy-rate {
          color: #58bd7d;
        }

        .sell-rate {
          color: #bd5858;
        }
      }
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-timeline {
  display: flex;
  width: 90%;
  margin-inline: auto;
  flex-direction: column;

  @include pmx-pre-lg {
    margin-top: 32px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    text-align: right;
    padding-right: 8%;

    @include pmx-pre-lg {
      font-size: 20px;
      padding-right: unset;
    }
  }

  .timelines {
    width: 100%;
    height: 240px;
    margin-top: 80px;
    margin-bottom: 128px;
    border: 1px solid #ceddf2;
    border-right: none;
    border-radius: 16px 0px 0px 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @include pmx-pre-lg {
      height: auto;
      grid-template-columns: unset;
      grid-auto-flow: row;
      border: none;
      margin-bottom: unset;
    }

    .timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        margin-top: 120px;

        @include pmx-pre-lg {
          margin-top: unset;
        }
      }

      @include pmx-pre-lg {
        padding-bottom: 56px;
        border-radius: 0px;

        &:nth-child(1) {
          border: 1px solid #ceddf2;
          border-right: none;
          border-bottom: none;
        }
        &:nth-child(2) {
          border: 1px solid #ceddf2;
          border-left: none;
          border-bottom: none;
        }
        &:nth-child(3) {
          border: 1px solid #ceddf2;
          border-right: none;
          border-bottom: none;
        }
        &:nth-child(4) {
          border: 1px solid #ceddf2;
          border-left: none;
          border-bottom: none;
        }
        &:nth-child(5) {
          border: 1px solid #ceddf2;
          border-right: none;
          border-bottom: none;
        }
        &:nth-child(6) {
          border: 1px solid #ceddf2;
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
      }

      span {
        font-size: 16px;
        font-weight: 700;
        color: #3772ff;
        position: absolute;
        top: -56px;

        @include pmx-pre-lg {
          font-size: 14px;
          top: -44px;
        }
      }

      .blue-circle {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #739dff7e;
        border-radius: 50%;
        opacity: 1;
        position: absolute;
        top: -20px;

        .inner {
          width: 30px;
          height: 30px;
          background: radial-gradient(
            100% 100% at 50% 100%,
            #739dff 3.12%,
            #3772ff 100%
          );
          border-radius: 50%;
          opacity: 1;
          z-index: 2;
          position: absolute;
        }
      }

      p {
        margin-top: 32px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        white-space: pre-wrap;

        @include pmx-pre-lg {
          font-size: 12px;
        }
      }
    }
  }
}

@import "core/styles/refrences/index";

.recent-transactions-container {
  width: 100%;

  .dashboard-recent-transactions {
    padding: 24px 24px;
    margin: 20px auto 32px;

    .body {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .transactions {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: auto;
        width: 100%;
        margin-top: 24px;
        column-gap: 24px;
        row-gap: 24px;

        .empty-box {
          margin: auto;
          grid-column: 2;
        }
      }

      > .divider {
        display: block;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid $border;
        margin-top: 24px;
      }
    }

    .head-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 20px;
        font-weight: 700;
      }

      a {
        display: inline-flex;
        align-items: center;
        justify-content: end;
        color: #3772ff;
        font-size: 14px;
        font-weight: 600;
        border-radius: 16px;
        border: 1px solid rgba(35, 38, 47, 0.2);
        background: #fff;
        padding: 8px 16px;

        svg {
          margin-right: 7px;
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .recent-transactions-container {
    padding: 0 24px;
    box-sizing: border-box;

    .dashboard-recent-transactions {
      margin: 40px auto 0;
      padding: 24px 16px 16px;

      .body {
        .divider {
          margin-top: 24px;
        }

        .head-row {
          padding: 0 8px;

          h3 {
            font-size: 14px;
          }

          a {
            font-size: 14px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }

        .transactions {
          margin-top: 16px;
          grid-template-columns: auto;
          grid-template-rows: auto;
          row-gap: 16px;
          column-gap: 16px;

          .empty-box {
            grid-column: 1;
          }
        }
      }
    }
  }
}

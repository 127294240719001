@import "../../../../../../core/styles/refrences/index.scss";

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset;
  }

  40%,
  100% {
    background-position: 140px + $avatar-offset;
  }
}

.referral-info-skeleton {
  display: block;
  margin-right: 32px;
  box-sizing: border-box;
  flex-direction: column;
  width: 50%;
  height: 100%;

  .referral-card-skeleton {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px 27px;
    box-sizing: border-box;
    border-radius: 30px;
    background: #ffffff;
    border: 1px solid #c2d1f7;
    position: relative;
    height: 100%;

    .title-skeleton {
      margin: auto;
      width: 40%;
      height: 31px;
      border-radius: 5px;
      @include background-gradient;
      animation: shine-avatar $animation-duration infinite linear;
    }

    .stats-skeleton {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

      .row-skeleton {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 24px;
        height: 25px;

        span {
          display: flex;
          width: 100px;
          height: 16px;
          border-radius: 5px;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
        }

        p {
          display: flex;
          width: 100px;
          height: 16px;
          border-radius: 5px;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
        }
      }
      .invite-code-skeleton {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;
          width: 100px;
          height: 16px;
          border-radius: 5px;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
        }

        div {
          height: 70px;
          width: 220px;
          border-radius: 12px;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;

          @include pmx-pre-lg {
            height: 48px;
          }
        }
      }
    }
  }

  .payout-button-skeleton {
    display: flex;
    margin: auto;
    margin-top: 20px;
    width: 100%;
    height: 56px;
    border-radius: 12px;
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
  }

  @include pmx-pre-lg {
    width: 100%;
    margin-right: 0px;

    > {
        border-radius: 5px;
    }

    .referral-card-skeleton .stats-skeleton {
        gap: 0px;
        .row-skeleton:not(:first-child) {
            margin-top: 8px;
        }

        .invite-code-skeleton {
            div {
                width: 167px;
                height: 48px;
                border-radius: 16px;
            }
        }
    }

    .payout-button-skeleton {
        height: 48px;
        border-radius: 16px;
    }
  }
}

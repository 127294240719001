@import "core/styles/refrences/index";

.pmx-frequently-asked-questions {
  display: flex;
  margin-top: 64px;
  width: 75%;
  margin-inline: auto;

  @include pmx-pre-lg {
    width: 90%;
  }

  .ant-collapse {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: unset;
    border: none;

    .ant-collapse-item {
      border: 1px solid #f0f0f0;
      border-radius: 24px;

      .ant-collapse-header {
        align-items: center;
        padding: 24px;

        .ant-collapse-expand-icon {
          @include pmx-pre-lg {
            height: 4px;
          }
        }

        .ant-collapse-header-text {
          p {
            font-weight: 700;
            font-size: 18px;

            @include pmx-pre-lg {
              font-size: 14px;
            }
          }
        }
      }

      .ant-collapse-content {
        border-radius: 0px 0px 24px 24px;

        .ant-collapse-content-box {
          p {
            font-size: 16px;
            font-weight: 500;

            @include pmx-pre-lg {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

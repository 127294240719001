@import "core/styles/refrences/index";

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset;
  }

  40%,
  100% {
    background-position: 140px + $avatar-offset;
  }
}

.price-card-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-width: 257px;
  height: 100%;
  width: 100%;
  background: $white;
  border: 1px solid $border !important;
  padding: 14px;
  border-radius: 20px;
  box-sizing: border-box;

  &:last-child {
    margin-top: 5px;

    @include pmx-pre-lg {
      margin-top: 12px;
    }
  }

  @include pmx-pre-lg {
    border-radius: 10px;
    padding: 12px;
  }

  .button-skeleton {
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
    max-width: unset;
    height: 30px;
    border-radius: 16px;
    width: 100%;
  }

  .list-skeleton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 18px;

    .row-skeleton {
      display: flex;
      justify-content: space-between;

      .currency-info {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;

        .circle-skeleton {
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
          max-width: unset;
          height: 32px;
          border-radius: 50%;
          width: 32px;

          @include pmx-pre-lg {
            height: 20px;
            width: 20px;
          }
        }

        .info-skeleton {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .rate {
            align-self: flex-end;
          }
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;

        .value-skeleton {
          margin-top: 4px;
        }
      }

      p {
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        font-size: 14px;
        font-weight: 700;
        color: $blue;
        width: 80px;
        height: 12px;
        border-radius: 8px;

        @include pmx-pre-lg {
          width: 50px;
          height: 10px;
        }
      }

      span {
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        font-size: 14px;
        font-weight: 700;
        color: $blue;
        width: 40px;
        height: 12px;
        border-radius: 8px;

        @include pmx-pre-lg {
          width: 25px;
          height: 10px;
        }
      }

      .value-skeleton {
        color: $blue;
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        font-size: 12px;
        font-weight: 400;
        color: $blue;
        width: 40px;
        height: 10px;
        border-radius: 8px;
        display: block;

        @include pmx-pre-lg {
          width: 20px;
          height: 8px;
        }
      }
    }
  }
}

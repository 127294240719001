@import "core/styles/refrences/index";

.dashboard-slider {
  margin-top: 32px;
  position: relative;

  .next-navigation {
    backdrop-filter: blur(10px);
    position: absolute;
    background: #ffffff50;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    left: 0;
    top: calc(50% - 24px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transform: rotate(180deg);

    svg {
      width: 36px;
      height: 36px;
    }

    @include pmx-pre-lg {
      left: 8px;
      top: calc(50% - 18px);

      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  .pre-navigation {
    backdrop-filter: blur(10px);
    position: absolute;
    width: 48px;
    background: #ffffff50;
    height: 48px;
    border-radius: 10px;
    cursor: pointer;
    right: 0px;
    top: calc(50% - 24px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    svg {
      width: 36px;
      height: 36px;
    }

    @include pmx-pre-lg {
      right: 8px;
      top: calc(50% - 18px);

      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  .swiper {
    @include pmx-pre-lg {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: 100%;
    display: inline-flex;
    justify-content: start;
    bottom: 43px;

    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
      width: 8px;
      height: 8px;
    }

    .swiper-pagination-bullet-active {
      background: $white;
    }

    .swiper-pagination-bullet:first-child {
      margin-right: auto;
    }

    .swiper-pagination-bullet:last-child {
      margin-left: auto;
    }
  }

  .slider {
    position: relative;
    display: flex;

    .swiper-wrapper {
    }
  }

  .slide {
    display: flex;
    padding-bottom: 19px;
    position: relative;

    .card {
      width: 100%;
      padding: 40px 32px;
      justify-content: start;
      align-items: start;
      background: linear-gradient(180deg, #3772ff 0%, #fff 135.67%);
      // background: url("../../../../../assets/images/dashboard/desfx 1.png")
      //   no-repeat center;
      // background-size: cover;
      border-radius: 40px;
      overflow: hidden;
      display: flex;

      h3 {
        font-size: 40px;
        font-weight: 600;
        margin-top: 24px;
        color: $white;
      }

      .right-ring {
        display: block;
        width: 285px;
        height: 285px;
        border: 75px solid #ffffff;
        opacity: 0.15;
        border-radius: 400px;
        position: absolute;
        right: -100px;
        top: -50%;
      }

      .left-ring {
        display: block;
        width: 250px;
        height: 250px;
        border: 60px solid #ffffff;
        opacity: 0.15;
        border-radius: 400px;
        position: absolute;
        left: 1%;
        bottom: -50%;
      }

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        color: $white;
        margin-top: 24px;
        max-width: 80%;
      }

      .pmx-button {
        margin-top: 21px;
        width: 167px;
        margin-right: 0;
        border-radius: 10px;

        button {
          border-radius: 10px;
          background: #3772ff;
          height: 48px;

          span {
            color: white;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }

      > img {
        position: absolute;
        height: auto;
        z-index: 10;
        left: 10%;
        bottom: 25%;
      }

      a {
        display: flex;
        width: fit-content;
      }
    }
  }
}

.dashboard-slider {
  @include pmx-pre-lg {
    padding: 0;
    margin: 72px 0 0;
    width: 100%;
    height: auto;
    position: relative;

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      position: absolute;
      width: 100%;
      bottom: 18px;
      right: 0;
      display: inline-flex;
      justify-content: center;

      .swiper-pagination-bullet {
        background: rgba(255, 255, 255, 0.5);
        opacity: 1;
        width: 8px;
        height: 8px;
      }

      .swiper-pagination-bullet-active {
        background: $white;
      }

      .swiper-pagination-bullet:first-child {
      }
    }

    .pre-navigation,
    .next-navigation {
      width: 48px;
      height: 48px;
      top: calc(50% - 20px);
    }

    .slider {
      overflow: visible;

      .slide {
        overflow: visible;
        padding-bottom: 0;
        min-height: 375px;

        .card {
          padding: 0 16px 40px;
          border-radius: 30px;
          box-sizing: border-box;
          // background: url("../../../../../assets/images/dashboard/desfx 1-mobile.png") no-repeat center;
          background-size: cover;
          margin: 0;
          height: 100%;

          .content {
            height: 100%;
            margin-top: 181px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
          }

          h3 {
            font-size: 24px;
            margin-top: 0;
          }

          p {
            margin-top: 10px;
            width: 100%;
            font-size: 12px;
            text-align: center;
          }

          .pmx-button {
            margin-top: 32px;
            margin-right: auto;
            width: 100%;
            border-radius: 15px;

            button {
              justify-content: center;
            }
          }

          .right-ring {
            display: none;
          }
        }

        img {
          top: -39px;
          left: calc(50% - 70px);
          width: 141px;
        }
      }
    }
  }
}

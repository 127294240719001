@import "core/styles/refrences/index";

.twofa-form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  height: 100%;

  .pmx-card {
    height: 100%;
  }

  .step-4 {
    .body {
      justify-content: start;
    }
  }

  .body {
    .stepper {
      display: flex;
      @include pmx-size(font-size, 16px, 12px);
      width: 100%;
      margin-top: 24px;
      margin-inline: auto;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;

      svg {
        width: 32px;
        height: 32px;
      }

      .circle {
        width: auto;


        .bottomText {
          display: flex;
          justify-content: center;
          @include pmx-size(font-size, 16px, 10px);
        }
      }

      hr {
        margin-inline: auto;
        min-width: 10px;
        @include pmx-size(width, 60px, 30px);
        height: 2px;
        background-color: $blue;
        border: $blue;
        justify-self: center;
        transform: translateY(-22px);
        opacity: 0.5;

        &.reached {
          opacity: 1;
        }
      }
    }

    @include pmx-size(font-size, 14px, 12px);
  }

  .head {
    h4 {
      font-family: YekanBakh;
      font-size: 24px;
      font-weight: 900;
      color: $black;
      text-align: center;
    }
  }

  .step-0 {
    height: auto;

    .body {
      display: flex;
      flex-direction: column;

      .bg {
        display: flex;
        width: 100%;
        background-color: #deffea;
        justify-content: center;
        border-radius: 8px;

        img {
          padding: 5%;
        }
      }

      div {
        margin-top: 40px;
        font-size: 16px;
        font-weight: 500;
        text-align: right;

        @include pmx-size(font-size, 16px, 12px);
      }

      .pmx-button {
        width: 100%;
        margin-top: 40px;

        button {
          height: 64px;
          max-width: unset;

          span {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .step-1 {
    .body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 8px;
        margin-top: 16px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-inline: auto;
          min-width: 232px;
          width: fit-content;
          gap: 8px;
          height: 48px;
          background-color: #f2f5fc;
          border-radius: 16px;
          font-weight: 500;

          span {
            font-family: Roboto;
            display: flex;
            font-size: 16px;
            font-weight: 600;
            height: 100%;
            align-items: center;
          }

          svg {
            height: 100%;
          }
        }
      }

      .pmx-button {
        width: 100%;
        margin-top: 24px;
        align-self: end;

        button {
          height: 64px;
          max-width: unset;

          span {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      p {
        text-align: center;
        margin: auto;
        font-size: 14px;
        margin-top: 24px;
        font-weight: 600;
      }
    }
  }

  .step-2 {
    .body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .buttons {
        display: flex;
        justify-content: space-between;
        justify-self: end;
        gap: 16px;
        width: 100%;
        margin-top: auto;
        height: 64px;

        .pmx-button {
          width: 100%;

          button {
            height: 64px;

            span {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }

      section {
        white-space: break-spaces;
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        grid-template-areas:
          "qr description"
          "qr link";
        @include pmx-size(grid-column-gap, 32px, 4px);

        div {
          grid-area: qr;
        }

        p {
          grid-area: description;
          font-size: 14px;
          font-weight: 600;
        }

        a {
          grid-area: link;
          align-self: end;
        }

        #key-link {
          display: flex;
          gap: 8px;
          align-items: center;
          color: #3772ff;
          font-size: 12px;
          justify-content: flex-end;
          font-weight: 600;
        }

        .qr-code {
          svg {
            path {
              &:last-child {
                fill: #3772ff;
              }
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      gap: 16px;
      height: 64px;

      .pmx-button {
        width: 75%;

        button {
          max-width: unset;

          span {
            font-size: 16px;
            font-weight: 600;
          }
        }

        &:last-child {
          width: 25%;
          justify-content: center;

          button {
            span {
              color: black;
              margin: auto;
            }
          }
        }
      }
    }
  }

  .step-3 {
    .body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      p {
        margin-top: 32px;
        text-align: center;
        font-weight: 600;
      }

      .code {
        margin-top: 23px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border-radius: 40px;
        padding: 16px;
        border: 1px solid var(--main-30-opacity, #c2d1f7);
        background: rgba(55, 114, 255, 0.05);
        font-size: 16px;
        outline: none;
        border: none;
        height: 41px;

        svg {
          cursor: pointer;
          margin-right: 24px;
          align-items: center !important;
          justify-content: center !important;
          width: 24px;
          height: 24px;

          @include pmx-pre-lg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .buttons {
        width: 100%;
        gap: 16px;
        display: flex;
        margin-top: 16px;
        height: 64px;

        .pmx-button {
          width: 75%;

          button {
            max-width: unset;
            height: 64px;

            span {
              font-size: 16px;
            }
          }

          &:last-child {
            width: 25%;
            justify-content: center;

            button {
              span {
                color: black;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }

  .step-4 {
    .body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin-top: 32px;
        font-weight: 600;
      }

      .fields {
        margin-top: 24px;
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: space-between;

        .column {
          width: 100%;

          label {
            text-align: right;
          }

          .pmx-input {
            height: 48px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 50%;
            margin: 0;
            max-width: unset;
            margin: 0 auto;
          }
        }
      }

      .buttons {
        width: 100%;
        gap: 16px;
        display: flex;
        margin-top: 16px;
        height: 64px;

        .pmx-button {
          width: 75%;

          button {
            max-width: unset;
            height: 64px;

            span {
              font-size: 16px;
            }
          }

          &:last-child {
            width: 25%;
            justify-content: center;

            button {
              span {
                color: black;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }

  .step-5 {
    height: auto;
    border-radius: 40px;

    .body {
      display: flex;
      flex-direction: column;

      .bg {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(237, 255, 244, 1);
        width: 100%;
        border-radius: 8px;
        margin-top: 24px;

        img {
          width: fit-content;
          height: auto;
          padding: 5%;
        }
      }

      h4 {
        color: rgba(54, 178, 116, 1);
        text-align: right;
        font-family: Yekan Bakh FaNum;
        font-size: 20px;
        font-weight: 600;
        margin-top: 24px;
      }

      span {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }

      .pmx-button {
        width: 100%;
        max-width: unset;
        margin-top: 24px;
        align-items: center;

        button {
          max-width: unset;
          width: 100%;
          height: 64px;

          span {
            margin-top: unset;
          }
        }
      }
    }
  }

  .step-6 {
    .body {
      display: flex;
      flex-direction: column;

      > h4 {
        color: black;
        text-align: right;
        font-family: Yekan Bakh FaNum;
        font-size: 20px;
        font-weight: 600;
        margin-top: 24px;
      }

      > span {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }

      .code-input {
        display: flex;
        direction: ltr;
        width: 100% !important;
        box-sizing: border-box;
        margin: 32px 0;

        &.error {
          > div > input {
            border: 1px solid $thin-red;
          }
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          > input {
            background: $white;
            border: 1px solid $lightGreyBorder;
            border-radius: 10px;
            font-size: 48px;
            color: $black;
            padding: 8px;
            margin-right: 1%;

            @include pmx-pre-md {
              margin: 0 5px;
              width: 52px !important;
              height: 52px !important;
              border-radius: 5px;
              font-size: 20px;
            }
          }
        }
      }

      .buttons {
        width: 100%;
        gap: 16px;
        display: flex;
        margin-top: 16px;
        height: 64px;

        .pmx-button {
          width: 75%;

          button {
            max-width: unset;
            height: 64px;

            span {
              font-size: 16px;
            }
          }

          &:last-child {
            width: 25%;
            justify-content: center;

            button {
              span {
                color: black;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .twofa-form-container {
    min-height: unset;

    .pmx-card:not(.step-5),
    .pmx-card:not(.step-0) {
      height: auto;
    }

    .body {
      .stepper {
        margin-top: 24px;
      }
    }

    .step-1 {
      .body {
        > p {
          margin-top: 24px;
        }

        section {
          flex-direction: column;
          margin-top: 24px;
          gap: 16px;

          a {
            min-height: unset;
            height: 48px;

            img,
            svg {
              // width: 170px;
              // height: 48px;
              margin: 0;
            }
          }
        }

        .pmx-button {
          margin-top: 32px;
        }
      }
    }

    .step-2 {
      .body {
        section {
          white-space: break-spaces;
          margin-top: 32px;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto auto auto;
          grid-template-areas:
            "description"
            "qr"
            "link";

          @include pmx-size(grid-column-gap, 32px, 4px);

          div {
            margin: 24px auto auto;
            grid-area: qr;
          }

          p {
            grid-area: description;
          }

          a {
            margin: 24px auto 0;
            grid-area: link;
            align-self: end;
          }
        }

        .buttons {
          margin-top: 24px;
          height: 64px;
        }
      }
    }

    .step-3 {
      .code {
        height: 56px;
        box-sizing: border-box;

        span {
          font-size: 12px;
        }
      }
    }

    .step-4 {
      .body {
        .buttons {
          justify-self: end;
          margin-top: 32px;
          height: 64px;

          .pmx-button {
            margin-top: unset;
          }
        }

        .fields {
          display: grid;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr;
          width: 100%;

          .column {
            width: 100%;

            label {
              text-align: right;
              height: 48px;
              font-weight: 700;
            }

            .pmx-input {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              width: 100%;
              margin: 0;
              max-width: unset;
              height: 48px;
              background: white !important;
            }
          }
        }
      }
    }

    .step-5 {

      .body {

        > h4 {
          font-size: 16px;
        }

        > span {
          font-size: 12px;
        }

        > span {
          font-size: 12px;
        }
      }
    }

    .step-6 {
      .body {
        display: flex;
        flex-direction: column;

        > h4 {
          color: black;
          text-align: right;
          font-family: Yekan Bakh FaNum;
          font-size: 16px;
          font-weight: 600;
          margin-top: 16px;
        }

        > span {
          margin-top: 16px;
          font-size: 12px;
          font-weight: 600;
        }

        .code-input {
          display: flex;
          direction: ltr;
          width: 100% !important;
          box-sizing: border-box;
          margin: 16px 0;

          &.error {
            > div > input {
              border: 1px solid $thin-red;
            }
          }

          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            > input {
              background: $white;
              border: 1px solid $lightGreyBorder;
              border-radius: 10px;
              font-size: 16px;
              color: $black;
              padding: 8px;
              margin-right: 1%;

              @include pmx-pre-md {
                margin: 0 5px;
                width: 32px !important;
                height: 32px !important;
                border-radius: 5px;
                font-size: 20px;
              }
            }
          }
        }

        .buttons {
          width: 100%;
          gap: 16px;
          display: flex;
          margin-top: 16px;
          height: 64px;

          .pmx-button {
            width: 75%;

            button {
              max-width: unset;
              height: 64px;

              span {
                font-size: 16px;
              }
            }

            &:last-child {
              width: 25%;
              justify-content: center;

              button {
                span {
                  color: black;
                  margin: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import "../../core/styles/refrences/index.scss";

.pmx-price-chart {
  .chart {
    @include pmx-size(width, 180px, 100px);
    @include pmx-lg {
      margin: auto !important;
    }
    margin: auto;
    padding-top: 20px;
    @include pmx-size(margin-bottom, 0px, 0px);
    @include pmx-size(margin-left, 20px, 0);

    .recharts-tooltip-wrapper {
      .my-tooltip {
        margin-top: 35px;

        p {
          font-size: 14px;
        }
      }
    }
    .recharts-responsive-container {
      background: $white !important;
    }
    .ant-card {
      margin: 0 !important;
      height: 55px;
      background: transparent;
    }

    .tooltip {
      background: khaki;
    }

    .gx-card-widget {
      box-shadow: none;
      border-radius: 10px;
    }

    .recharts-responsive-container {
      background: $chart-bg;
    }

    .chart-header {
      // background: red;
    }

    .chart-shadow1 {
      stop-opacity: 0.8;
      offset: 5%;
      -webkit-filter: blur(10px);
      filter: blur(10px);
      background-image: linear-gradient(
        to bottom,
        $chart-shadow -30%,
        rgba(255, 78, 130, 0) 94%
      );
    }

    .chart-shadow2 {
      stop-opacity: 0.8;
      offset: 95%;
      -webkit-filter: blur(10px);
      filter: blur(10px);
      background-image: linear-gradient(
        to bottom,
        $chart-shadow -30%,
        rgba(255, 78, 130, 0) 94%
      );
    }

    path {
      stroke-width: 1.5px !important;
    }
  }
}

@import "core/styles/refrences/index";

.pmx-order-card .pmx-basic-info-form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 401px;

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
  }

  .error-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    svg {
      path {
        fill: $thin-red;
      }
    }

    span {
      color: $thin-red;
    }
  }

  > .ant-input,
  .row > .ant-input {
    height: 64px;
    border-radius: 16px;
    margin-top: 24px;
    font-size: 20px;
    font-weight: 700;
    padding: 11px 21px;

    &.error {
      height: 91px;
      border: 1px solid $thin-red;
    }

    @include pmx-pre-lg {
      height: 64px;
      font-size: 14px;
    }
  }

  .ant-input-affix-wrapper {
    height: 64px;
    border-radius: 16px;
    padding: 11px 21px;
    margin-top: 24px;

    &.error {
      border: 1px solid $thin-red;
    }

    @include pmx-pre-lg {
      height: 64px;
    }
  }

  > .pmx-button {
    margin-top: 24px;

    button {
      height: 64px;
      justify-content: center;
      width: 100%;
      max-width: unset;
    }
  }
}

#email {
  font-family: YekanBakh-En !important;
}

.pmx-order-card .pmx-auth-result-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 401px;
  background: url("../../../../../../../assets/icons/home/order-card-circle-bg-success.png");
  background-size: 100%;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;

  &.error {
    background: url("../../../../../../../assets/icons/home/order-card-circle-bg-error.png");
    background-size: 100%;
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
  }

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;
  }

  > img {
    width: 80px;
    height: 80px;
    margin: 40px 0 0;
  }
}

@import "../../core/styles/refrences/index";

.pmx-input {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  padding: 13px 12px 13px;
  border: 1px solid $border;
  border-radius: 8px;
  width: calc(100% - 48px);
  // background: $outlinedBg;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: .2s border-color;


  &:hover {
    border-color: $lightPurple;
    transition: .2s border-color;
  }

  label {
    position: absolute;
    top: -24px;
    right: 0;
  }

  ::placeholder {
    color: rgba(35, 38, 47, 1);
    opacity: 0.3; /* Firefox */
  }

  input {
    background-color: $outlinedBg;
  }

  input {
    width: 100%;
    font-size: 16px;
    padding: 0;
    outline: none;
    border: 0;
    background: none;
    height: 100%;
  }

  .adornments {
    position: absolute;
    top: calc(50% - 15px);
    left: 15px;
    display: flex;
    align-items: center;
    height: 30px;

    &.right {
      right: 15px;
    }

    .password-toggle {
      display: flex;
      margin: auto;
      cursor: pointer;
    }

    img,
    svg,
    .anticon {
      max-width: 30px;
      max-height: 30px;
    }

    svg {
      path {
        fill: $lightPurple;
      }
    }
  }


}

@include pmx-pre-lg {
  .pmx-input {
    height: 48px;
    padding: 16px 15.5px;

    input {
      font-size: 12px;
    }
  }
}
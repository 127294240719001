@import "../../../../../core/styles/refrences/index.scss";

$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset;
  }

  40%,
  100% {
    background-position: 140px + $avatar-offset;
  }
}

.pmx-authentication-skeleton {
  display: flex;
  width: 100%;
}

.pmx-authentication-skeleton .skeleton-pros {
  margin-top: 24px;
  padding: 0;
  border: none;

  .body {
    flex-direction: column;
    .skeleton-head-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .skeleton-title {
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        width: 100px;
        height: 20px;
        font-size: 16px;
        border-radius: 8px;
      }

      > .skeleton-row {
        display: flex;
        gap: 8px;
        span {
          @include background-gradient;
          display: flex;
          font-size: 14px;
          animation: shine-avatar $animation-duration infinite linear;
          width: 80px;
          height: 15px;
          border-radius: 8px;
        }

        p {
          @include background-gradient;
          font-size: 14px;
          animation: shine-avatar $animation-duration infinite linear;
          width: 40px;
          height: 10px;
          border-radius: 8px;
          margin-top: 4px;
        }
      }
      margin-bottom: 16px;
    }

    .first-line,
    .second-line {
      display: flex;
      @include background-gradient;
      font-size: 12px;
      animation: shine-avatar $animation-duration infinite linear;
      width: 100%;
      height: 10px;
      border-radius: 8px;
      margin-top: 8px;
    }

    .third-line {
      display: flex;
      @include background-gradient;
      font-size: 12px;
      animation: shine-avatar $animation-duration infinite linear;
      width: 75%;
      height: 10px;
      border-radius: 8px;
      margin-top: 8px;
    }
  }
}

@import "core/styles/refrences/index";

.pmx-receive-method-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 300px;

  .iban-input {
    margin-top: 32px;
  }

  .add-iban-button {
    margin-top: 32px;
    button {
    max-width: unset;
      justify-content: center;
    }
  }

  .ant-radio-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    @include pmx-pre-lg {
      flex-direction: column;
    }

    .ant-radio-wrapper {
        span {
          font-size: 20px!important;
          font-weight: 700;
          white-space: nowrap;

          @include pmx-pre-lg {
            font-size: 16px;
          }
      }
    }
  }

  ul {
    list-style: none;
    width: 100%;
    border: 1px solid $border;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    max-height: 200px;
    overflow-y: auto;

    .ant-divider {
      margin: unset;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 16px;
      border-radius: 4px;

      &:hover {
        background: $extraLightBlue;
      }

      &.active {
        background: $extraLightBlue;
      }

      div {
        &:first-child {
          p {
            &:first-child {
              font-size: 20px;
              font-weight: 700;

              @include pmx-pre-lg {
                font-size: 16px;
              }
            }

            &:last-child {
              font-size: 16px;
              font-weight: 600;
              color: $dark-grey;

              @include pmx-pre-lg {
                font-size: 12px;
              }
            }
          }
        }

        &:last-child {
          width: 36px;
          height: 36px;
          border-radius: 4px;
          background: $light-red;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 3;

          svg {
            path {
              stroke: $delete-icon-hover-red
            }
          }

          &:hover {
            background: $delete-icon-hover-red;

            svg {
              path {
                stroke: $white;
              }
            }
          }
        }
      }
    }
  }

  @include pmx-pre-lg {
    padding: 0 16px;
  }

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .account-id-input::placeholder {
    text-align: right;
  }

  .methods {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;

    .method-item {
      background: $extraLightBlue;
      border: 1px solid $lightGreyBorder;
      padding: 24px;
      gap: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      cursor: pointer;
      box-sizing: border-box;
      height: 137px;

      @include pmx-pre-lg {
        height: 100px;
        padding: 16px;
      }

      > div p, > div span {
        @include pmx-pre-lg {
          font-size: 14px;
        }
      }

      &.disabled {
        opacity: .5;
      }

      &.active {
        background: $primary;

        > div {
          span, p {
            color: $white;
          }
        }

        .inner-circle {
          background: $blue;
        }
      }

      > div:first-child {
        width: 70%;

        @include pmx-pre-lg {
          width: 90%;
        }

        span {
          font-size: 20px;
          font-weight: 700;

          @include pmx-pre-lg {
            font-size: 14px;
          }
        }

        p {
          font-size: 16px;

          @include pmx-pre-lg {
            font-size: 12px;
          }
        }
      }

      .circle-badge {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        width: 40px;
        height: 40px;
        border-radius: 25px;

        @include pmx-pre-lg {
          width: 24px;
          height: 24px;
        }


        .inner-circle {
          width: 16px;
          height: 16px;
          border-radius: 50px;

          @include pmx-pre-lg {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }


}

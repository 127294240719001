.pmx-vpn-modal {
  p {
    font-weight: 500;
    margin-top: 16px;
  }

  .pmx-button {
    max-width: unset;
    margin-top: 32px;
    button {
      max-width: unset;
    }
  }
}
@import "../../../../../../../core/styles/refrences/index.scss";

.qr-modal {
  top: 10%;
}

.pmx-order-card .pmx-enter-wallet-deposit-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;


  .choose-wallet-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h3 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 24px;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    > p {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      text-align: right;
      margin-top: 8px;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }

    .choose-network-button {
      background-color: #f5f8ff;
      border: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      padding: 0 16px;
      margin-top: 16px;
      cursor: pointer;
      height: 64px;

      @include pmx-pre-lg {
        height: 48px;
      }

      svg {
        @include pmx-pre-lg {
          width: 24px;
          height: 24px;
        }
      }

      span {
        color: #3772ff;
        font-size: 20px;
        font-weight: 700;
        font-family: YekanBakh-En !important;
        direction: ltr;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }
    }

    .network-field {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;
      margin-top: 16px;

      .tour-el {
        width: 100%;
      }

      .network-option {
        background: $extraLightBlue;
        padding: 7px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 48px;
        height: 48px;
        aspect-ratio: 0.5/0.5;

        @include pmx-pre-lg {
          width: 24px;
          height: 24px;
        }
      }

      .ant-input {
        margin-top: 0;
        direction: ltr;
      }
    }

    .ant-input {
      font-family: YekanBakh-En !important;

      &::placeholder {
        text-align: right;
      }

      text-align: left;
    }
  }

  .choose-network-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 331px;
    overflow: auto;

    .pmx-button {
      button {
        max-width: unset;
        margin-top: 16px;
        padding: 8px 16px;

        span {
          color: black;
          font-weight: 900;
          font-size: 20px;
        }
      }
    }

    ul {
      padding: unset;
      list-style: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      scrollbar-width: 2px;
      

      li {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        padding: 8px 16px;

        span {
          font-size: 20px;
          font-weight: 900;
          font-family: YekanBakh-En !important;
          direction: ltr;
          transform: translateY(2px);

          @include pmx-pre-lg {
            font-size: 12px;
          }
        }

        svg {
          @include pmx-pre-lg {
            width: 14px;
            height: 12px;
          }
        }
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-order-card .pmx-set-password-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 401px;

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;
  }

  .error-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    svg {
      path {
        fill: $thin-red;
      }
    }

    span {
      color: $thin-red;
    }
  }

  .ant-input {
    margin-top: 24px;

  }

  > .pmx-button {
    margin-top: 24px;

    button {
      height: 64px;
      justify-content: center;
      width: 100%;
      max-width: unset;
    }
  }


}
@import "../../../core/styles/refrences/index.scss";

.pmx-navigation {
  position: fixed;
  right: -100%;
  background: $white;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  display: flex;
  z-index: 100;
  height: 100%;
  flex-direction: column;
  margin-inline: auto;
  padding: 20px 24px 40px;
  transition: .5s right;

  &.active {
    right: 0;
    transition: .5s right;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    .close-icon {
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      background-color: #e6e6e6;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
      cursor: pointer;

      &:first-of-type {
        border: 1px solid #e6e6e6;
      }

      &:last-of-type {
        background: #3772ff;
        color: white;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
    list-style: none;
    padding: unset;

    li {
      display: flex;
      width: 100%;

      a {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 700;
        color: black;
      }
    }
  }

  .socials {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .social-items {
      display: flex;
      gap: 24px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-socials {
    display: flex;
    width: 75%;
    margin-inline: auto;
    justify-content: space-between;

    @include pmx-pre-lg {
        width: 90%;
        flex-direction: column;
    }

    .col {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 32px;
            font-weight: 700;
            text-align: right;
            
            @include pmx-pre-lg {
                font-size: 20px;
            }
        }
    
        p {
            margin-top: 24px;
            font-size: 16px;
            font-weight: 500;
            text-align: right;

            @include pmx-pre-lg {
                margin-top: 16px;
                font-size: 12px;
            }
        }
    }

    .socials {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 32px;

        @include pmx-pre-lg {
            margin-top: 24px;
        }

        .social-item {
            background-color: #E5F2FF;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 64px;
            border-radius: 8px;

            @include pmx-pre-lg {
                width: 100%;
            }

            svg {
                width: 32px;
                height: 32px;

                @include pmx-pre-lg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
@import "core/styles/refrences/index";

.pmx-pagination {
  display: flex;
  margin: 2% auto;
  width: auto;
  justify-content: center;

  button {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: $skyBlue;
    border-radius: 10px;
    border: 1px solid $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    box-sizing: border-box;


    &:first-child {
      margin-right: 0;
    }

    &:hover,
    &.active {
      background: $primary;
      color: $white;

      path {
        fill: $white;
      }
    }

    path {
      fill: $lightBlue;
    }
  }

  .pages {
    display: flex;
    flex-wrap: wrap;

    button:first-child {
      margin-right: 8px;
    }
  }

  @include pmx-pre-lg {
    transform: translateX(-7%);
  }
}

@import "core/styles/refrences/index";


$base-color: #ddd;
$shine-color: #e8e8e8;
$animation-duration: 1.6s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
                  90deg,
                  $base-color 0px,
                  $shine-color 40px,
                  $base-color 80px
  );
  background-size: 600px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -100px + $avatar-offset;
  }

  40%,
  100% {
    background-position: 140px + $avatar-offset;
  }
}

.order-item-skeleton {
  display: flex;
  flex-direction: column;
  background: $transaction-bg;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 0;
  border: 1px solid $border;

  .dashed-divider {
    width: 100%;
    margin-top: 16px;
    height: 1px;
    border: 1px solid transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='1' viewBox='0 0 10 1' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0' y1='0.5' x2='10' y2='0.5' stroke='%23C2D1F7' stroke-width='1' stroke-dasharray='5,5' /%3E%3C/svg%3E");
    background-repeat: repeat-x;

    @include pmx-pre-lg {
      margin-top: 12px;
    }
  }

  .head-skeleton {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;

    .pair-skeleton {
      display: flex;

      .title-skeleton {
        display: flex;
        flex-direction: column;
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        width: 70px;
        height: 24px;
        border-radius: 8px;

        span {
          width: 100%;
          height: 10px;
          font-size: 14px;
          color: $gray-span;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
          border-radius: 8px;
        }

        p {
          width: 30px;
          height: 10px;
          font-size: 12px;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
          border-radius: 8px;
        }
      }

      .icons-skeleton {
        display: flex;

        .img-skeleton {
          background: $transaction-bg;
          border-radius: 8px;
          padding: 3px;
          box-sizing: border-box;
          width: 60px;
          height: 20px;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
        }

        .img-skeleton:last-child {
          margin-right: -16px;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
        }
      }
    }

    .badge-skeleton {
      @include background-gradient;
      animation: shine-avatar $animation-duration infinite linear;
      border-radius: 8px;

      width: 80px;
      height: 30px;
    }
  }

  > .divider {
    display: inline-block;
    width: 100%;
    height: 1px;
    border: 1px dashed $blue;
    margin-top: 16px;
  }

  .data-skeleton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 0 16px;
    margin-top: 16px;
    box-sizing: border-box;

    .col-skeleton {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 8px;

      > div {
        display: flex;
        flex-direction: column;

        .text-overflow {
          width: 100px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          direction: ltr;
        }

        > span {
          width: 45px;
          height: 10px;
          font-size: 14px;
          color: $gray-span;
          white-space: nowrap;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
          border-radius: 8px;
        }

        p {
          width: 30px;
          height: 10px;
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
          @include background-gradient;
          animation: shine-avatar $animation-duration infinite linear;
          border-radius: 8px;
          margin-top: 4px;

          span {
            font-size: 10px;
            @include background-gradient;
            animation: shine-avatar $animation-duration infinite linear;
            border-radius: 8px;
          }
        }
      }

      > div:last-child {
        margin-top: auto;
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        border-radius: 8px;
      }

      .details-button-skeleton {
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        border-radius: 8px;
        width: 99px;
        height: 35px;
        transform: translateY(10px);
        @include pmx-pre-lg {
          transform: translateY(20px);
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .order-item-skeleton {
    padding-top: 12px;
    height: auto;


    .head-skeleton {
      .pair-skeleton {
        .title-skeleton {
          height: 16px;

          span {
            font-size: 12px;
          }

          p {
            font-size: 10px;
          }
        }

        .icons-skeleton {
          img {
            width: 20px;
            height: 20px;
          }

          img:last-child {
            margin-right: -10px;
          }
        }
      }

      .badge-skeleton {
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
        height: 20px;
      }
    }

    .dashed-divider {
      margin-top: 12px;
    }

    .data-skeleton {
      padding: 0 12px;
      margin-top: 12px;

      .col-skeleton {
        > div {
          > p,
          > span {
            font-size: 12px;
            height: 8px;
          }
        }

        .details-button-skeleton {
          height: 35px;
          transform: unset;
        }

        > div:last-child {
          margin-top: 12px;
        }
      }
    }
  }
}

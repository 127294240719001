@import "../../../../../core/styles/refrences/index.scss";

.pmx-services {
  margin-inline: auto;
  margin-top: 120px;
  width: 75%;

  @include pmx-pre-lg {
    width: 90%;
    margin-top: 40px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    text-align: center;

    @include pmx-pre-lg {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 500;
    color: #777e90;
    text-align: center;
    margin-top: 8px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .services {
    display: flex;
    gap: 32px;
    margin-top: 32px;

    @include pmx-pre-lg {
      flex-wrap: wrap;
      gap: 16px;
    }

    .service-info {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border: 1px solid #f0f0f0;
      border-radius: 16px;
      width: 30%;
      justify-content: space-between;

      @include pmx-pre-lg {
        padding: 8px;
        width: calc(50% - 26px);

        &:last-of-type {
          width: 100%;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 700;
        margin-top: 24px;

        @include pmx-pre-lg {
          font-size: 14px;
          text-align: center;
        }
      }

      p {
        margin-top: 24px;
        font-weight: 500;
        font-size: 14px;
        color: #777e90;
        text-align: right;

        @include pmx-pre-lg {
          margin-top: 8px;
          font-size: 10px;
          text-align: center;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-top: 16px;
        padding: 4px 16px;
        height: 38px;
        font-weight: 700;
        font-size: 14px;
        color: black;
        text-decoration: none;
        border-radius: 16px;
        background: #f8f8f9;
        cursor: pointer;

        @include pmx-pre-lg {
          width: calc(100% - 32px);
          margin-inline: auto;
        }
      }

      .swap-icon {
        display: flex;
        width: 64px;
        height: 64px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #dcf5ea;

        @include pmx-pre-lg {
          width: 48px;
          height: 48px;
          margin-inline: auto;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .chat-icon {
        display: flex;
        width: 64px;
        height: 64px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #e5f2ff;

        @include pmx-pre-lg {
          width: 48px;
          height: 48px;
          margin-inline: auto;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .authentication-icon {
        display: flex;
        width: 64px;
        height: 64px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #f4e8ff;

        @include pmx-pre-lg {
          width: 48px;
          height: 48px;
          margin-inline: auto;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

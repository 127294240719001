@import "core/styles/refrences/index";

.pmx-dashboard {
  display: flex;
  justify-content: start;
  width: 100%;
  background: $dashboard-bg;
  overflow-y: hidden;

  .panel {
    overflow-y: scroll;
    height: 100vh;
    width: 100%;

    main {
      display: flex;
      box-sizing: border-box;
    }
  }
}

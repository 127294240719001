@import "core/styles/refrences/index";

.pmx-dashboard-index-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  overflow-x: hidden;

  @include pmx-pre-lg {
    padding: 0 24px 24px;
  }

  .head-row {
    display: flex;
    column-gap: 24px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    @include pmx-pre-lg {
      flex-direction: column;
      gap: 12px;
    }

    .prices {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25%;
      height: 257px;
      gap: 16px;

      @include pmx-pre-lg {
        width: 100%;
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-dashboard-index-page {
    overflow: hidden;
    padding: 0 0 140px;

    .head-row {
      padding: 0 24px;

      .prices-and-level {
        width: 100%;

        .prices {
          .price-card:last-child {
            margin-top: 12px;
          }
        }
      }

      .boxes {
        flex-direction: column;
        margin-top: 24px;

        .invite-box,
        .invite-box-skeleton {
          margin-right: 0;
          margin-top: 12px;
        }
      }
    }
  }
}

@import "../../../../../../../core/styles/refrences/index.scss";

.pmx-bill-preview-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: auto;

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
    text-align: center;

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  > p {
    color: #909090;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    margin-top: 8px;
    text-align: center;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .bill-preview-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f9fcff;
    border-radius: 8px;
    position: relative;
    margin-top: 48px;

    .images {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      gap: 8px;
      position: absolute;
      top: -40px;
      margin-inline: auto;
      width: 100%;

      .coin,
      .swap {
        z-index: 1;
      }

      .coin {
        width: 64px;
        height: 64px;

        @include pmx-pre-lg {
          width: 40px;
          height: 40px;
        }
      }

      .swap {
        @include pmx-pre-lg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .bill-info {
      padding: unset;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 0 24px 24px;
      justify-content: space-between;
      width: 100%;
      margin: unset;
      box-sizing: border-box;
      margin-top: 64px;
      height: auto;

      .bill-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          &:first-child {
            font-size: 16px;
            font-weight: 700;
            opacity: 0.5;
            color: $dark-grey;
          }

          &:last-child {
            font-size: 16px;
            font-weight: 700;
            color: $dark-grey;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: space-between;

            &.copiable {
              color: #3772ff;


            }

            &.long-text {
              span {
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            svg {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .ant-collapse {
    border: 1px solid #d4781c;
    margin-top: 16px;

    .ant-collapse-item {
      .ant-collapse-header {
        background-color: #d4781c1a;
        height: 64px;
        display: flex;
        align-items: center;

        .ant-collapse-header-text {
          color: #d4781c;
          font-size: 16px;
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 4px;

          @include pmx-pre-lg {
            font-size: 12px;
          }

          svg {
            transform: translateY(-1px);
          }
        }

        .ant-collapse-expand-icon {
          .preview-button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            font-weight: 700;
            color: #d4781c;
            border-radius: 8px;
            background-color: white;
            height: 41px;
            padding: 0 16px;
          }
        }
      }

      .ant-collapse-content {
        background-color: #d4781c1a;
        border-top: 1px solid #d4781c;

        p {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  .rules {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    flex-wrap: wrap;


    svg {
      transform: translateY(-2px);
      margin-left: 4px;
    }

    a {
      text-decoration: none;
      color: #3772ff;
      cursor: pointer;
    }
  }

  .buttons {
    width: 100%;
    margin: 24px 0 24px;
    display: flex;
    gap: 24px;

    .pmx-button {
      width: auto;

      button {
        height: 64px;
        max-width: unset;

        @include pmx-pre-lg {
          height: 48px;
        }
      }

      button.ordercard_outlined {
        justify-content: center;

        span {
          color: $black;
          font-weight: 700;
        }
      }
    }

    .pmx-button:first-child {
      flex-grow: 5;
    }

    .pmx-button:last-child {
      flex-grow: 1;
    }
  }
}

@import "../../core/styles/refrences/index.scss";

.pmx-button {
  width: 100%;
  margin: 0 auto;

  cursor: pointer;
  box-sizing: border-box;

  button {
    box-sizing: border-box;
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 16px;
    font-weight: 700;
    transition: 0.2s;
    max-width: 300px;

    @include pmx-pre-lg {
      padding: 12px 10px;
    }

    span {
      font-size: 16px;
      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    > .anticon,
    > svg,
    > img {
      &:last-child {
        margin-right: 8px;
      }

      &:first-child {
        margin-left: 8px;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.94;
      transition: 0.2s;
    }

    &.disable {
      opacity: 0.7;
      cursor: revert !important;
    }

    &.primary {
      background: $blue;
      color: $white;
    }

    &.error {
      background: #BD5858;
      color: $white;
    }

    &.cancel {
      background: $cancel;
      color: $red;
    }

    &.white {
      background: $white;
      border: none;
      color: $blue;
      font-size: 20px;
      height: 48px;
      font-weight: 500 !important;
      justify-content: space-between;
      padding: 12px 18px;
    }

    &.outlined {
      background: $outlinedBg;
      border: 1px solid $border !important;
      height: 56px;
      transition: 0.4s;
      font-weight: 400 !important;
      justify-content: space-between;
      padding: 12px 18px;

      span {
        font-size: 16px;
        color: $blue;
        font-weight: 700;
      }

      &:hover {
        color: $black;
        transition: 0.4s;
        border: 1px solid $lightPurple !important;
      }
    }

    &.ordercard_outlined {
      background: $white;
      border: 1px solid $border !important;
      height: 64px;
      transition: 0.4s;
      font-weight: 400 !important;
      justify-content: space-between;
      padding: 12px 18px;

      @include pmx-pre-lg {
        height: 48px;
      }

      span {
        font-size: 16px;
        color: $blue;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }
    }

    &.transparent {
      background: transparent;
      border: 1px solid $blue !important;
      transition: 0.4s;
      font-weight: 400 !important;
      justify-content: center;
      padding: 8px;
      width: 99px;
      height: 35px;
      border-radius: 8px;

      svg {
        path {
          fill: $blue;
        }
      }

      span {
        font-size: 16px;
        color: $blue;
      }

      &:hover {
        transition: 0.4s;
        border: none;
        background-color: $blue;

        svg {
          path {
            fill: $white;
          }
        }

        span {
          color: $white;
        }
      }
    }

    &.success {
      background: $success;
      color: $white;
    }

    &.secondary {
      background: $purple;
      color: $white;
    }

    &.buy {
      color: #36b274;
      border: 1px solid #36b274;
      background: rgba(54, 178, 116, 0.05);
      border-radius: 16px;
      height: 32px;
    }
  }
}

@include pmx-pre-lg {
  .pmx-button {
    button {
      max-width: unset;
    }
  }
}

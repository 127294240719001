@import "../../../../../core/styles/refrences/index.scss";

.pmx-articles {
  width: 75%;
  margin-inline: auto;
  margin-top: 120px;

  @include pmx-pre-lg {
    width: 100%;
    margin-top: 40px;
  }

  h3 {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
    font-weight: 700;

    @include pmx-pre-lg {
      font-size: 20px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      border: 1px solid #23262f33;
      color: #3772ff;
      font-size: 14px;
      font-weight: 700;
      gap: 8px;
      padding: 8px 16px;
      cursor: pointer;

      svg {
        @include pmx-pre-lg {
          display: none;
        }
      }
    }
  }

  .articles {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 64px;

    @include pmx-pre-lg {
      margin-top: 32px;
    }

    article {
      display: flex;
      flex-direction: column;
      border: 1px solid #f0f0f0;
      border-radius: 16px;
      width: 32%;

      @include pmx-pre-lg {
        margin-inline: auto;
        width: 90%;
      }

      img {
        border-radius: 16px 16px 0 0;
        height: 50%;
      }

      .body {
        box-sizing: border-box;
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        padding: 24px;

        h4 {
          font-size: 16px;
          font-weight: 700;
          height: 20%;
        }

        p {
          margin-top: 16px;
          display: flex;
          width: 100%;
          height: 50%;
          font-size: 12px;
          font-weight: 500;
          color: #777e90;
        }

        .footer {
          display: flex;
          margin-top: 16px;
          width: 100%;
          height: 30%;
          align-items: center;
          justify-content: space-between;

          a {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
          }

          span {
            font-size: 14px;
            font-weight: 500;
            color: #777e90;
          }
        }
      }
    }

    .pre-navigation,
    .next-navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      position: absolute;
      width: 32px;
      height: 32px;
      z-index: 1;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
    }

    .next-navigation {
      left: 4px;
      transform: translateY(80px);
    }

    .pre-navigation {
      right: 4px;
      transform: translateY(80px);
    }
  }
}

@import "core/styles/refrences/index";

.pmx-currency-selection-modal {
  left: 37%;
  top: 5%;
  padding: 0px;

  @include pmx-pre-lg {
    max-width: 327px;
    left: 8%;
  }

  .modal-head {
    box-sizing: border-box;
    padding-inline: 32px;

    @include pmx-pre-lg {
      padding-inline: 16px;
    }
  }

  .modal-content {
    .ant-input-affix-wrapper {
      align-items: center;
      display: flex;
      height: 48px;
      padding: 16px;
      justify-content: start;
      background-color: #f5f5f5;
      width: 90%;
      margin: 0 auto;
      .ant-input {
        background-color: #f5f5f5;
        margin-right: 16px;
        font-size: 16px;
      }
    }

    .popular-currencies {
      display: flex;
      gap: 8px;
      margin-top: 16px;
      margin-right: 16px;
      .popular-currency {
        cursor: pointer;
        display: flex;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        padding: 2px 8px;
        gap: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
        }

        span {
          font-weight: 700;
          transform: translateY(4px);
        }
      }
    }

    .currencies {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      overflow-y: auto;
      max-height: 400px;

      .currency {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        cursor: pointer;

        &:hover {
          background-color: #f8f8f9;
        }

        .currency-info {
          display: flex;
          gap: 8px;
          margin-right: 16px;
          img {
            width: 40px;
            height: 40px;
          }

          .col {
            display: flex;
            flex-direction: column;

            span {
              &:first-of-type {
                font-size: 16px;
                font-weight: 700;
              }

              &:last-of-type {
                font-size: 12px;
                font-weight: 700;
                color: #bdbdbd;
                direction: rtl !important;
                text-align: justify !important;
              }
            }
          }
        }
      }
    }
  }
}

.pmx-order-card .pmx-calculator {
  .side-selection {
    display: flex;

    border-bottom: 1px solid #e6e6e6;

    .side {
      display: flex;
      gap: 8px;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 24px 0;
      color: #909090;
      font-weight: 700;
      font-size: 16px;
      margin-inline: auto;
      cursor: pointer;

      @include pmx-pre-lg {
        font-size: 14px;
      }

      svg {
        transform: translateY(-2px);
      }

      &.active {
        color: black;
        border-bottom: 3px solid black;

        svg {
          path {
            stroke: black;
          }
        }
      }
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;

    @include pmx-pre-lg {
      padding: 16px;
    }

    .ant-input-affix-wrapper {
      padding: 20px 24px;
      height: 80px;

      @include pmx-pre-lg {
        height: 64px;
        padding: 8px 8px 8px 16px;
        border-radius: 16px;
      }

      .ant-input {
        font-size: 26px;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 14px;
        }
      }

      .ant-input-prefix {
        .currency-selection {
          cursor: pointer;
          padding: 5px;
          display: flex;
          background: #fafafa;
          border-radius: 81px;
          align-items: center;
          gap: 8px;
          justify-content: space-between;

          &.disabled {
            background-color: $lightGreyBorder;
          }

          .col {
            display: flex;
            flex-direction: column;
            text-align: left;

            span {
              &:first-of-type {
                font-size: 12px;
                font-weight: 900;
                color: $dark-grey;

                @include pmx-pre-lg {
                  font-size: 12px;
                }
              }

              &:last-of-type {
                font-size: 12px;
                font-weight: 700;
                color: $dark-grey;
                opacity: 60%;
                text-align: justify !important;
              }
            }
          }

          img {
            width: 27px;
            height: 27px;
            border-radius: 50px;

            @include pmx-pre-lg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .ant-input-suffix {
        margin-inline-start: 8px; //فاصله ی بین پرداختی/دریافتی تا اینپوت

        div {
          display: flex;
          gap: 16px;

          @include pmx-pre-lg {
            gap: 8px;
          }

          span {
            display: flex;
            font-weight: 700;
            font-size: 16px;

            @include pmx-pre-lg {
              font-size: 12px;
            }

            &.type {
              color: #b3b3b3;
            }

            &.unit {
              color: black;
            }
          }
        }
      }
    }

    .pmx-button {
      button {
        height: 64px;
        max-width: unset;

        @include pmx-pre-lg {
          height: 48px;
        }
      }
    }

    .dollar-equivalent {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      p {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 16px;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 12px;
        }

        &:last-child {
          color: $blue;
          font-weight: 600;
        }
      }
    }
  }

}

@import "core/styles/refrences/index";

.pmx-orders-container {
  width: 100%;
  padding: 32px;
  box-sizing: border-box;

  .pmx-orders {
    padding: 32px 34px;

    .body {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .orders {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, 2fr);
        grid-template-rows: auto;
        width: 100%;
        margin-top: 24px;
        column-gap: 16px;
        row-gap: 16px;

        .empty-box {
          grid-column: 2;
          margin: auto;
        }
      }

      .filter-row {
        display: flex;
        width: 100%;
        // margin-top: 21px;
        position: relative;

        ul,
        li {
          margin: 0;
          list-style: none;
        }

        .pmx-button {
          margin-left: 0;
          margin-right: auto;
          width: auto;

          button {
            height: 48px;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .tabs {
        width: auto;
        display: flex;
        flex-direction: column;

        .select-row {
          width: auto;
          display: flex;
          position: relative;

          .selector-underline {
            width: 68px;
            height: 5px;
          }

          span {
            width: 68px;
            font-size: 20px;
            font-weight: 700;
            margin-right: 64px;
            text-align: center;
            cursor: pointer;
          }

          span:first-child {
            margin-right: 0;
          }

          .selector-underline {
            position: absolute;
            bottom: -21px;
            right: 0;
            content: " ";
            display: block;
            width: 68px;
            height: 5px;
            border-radius: 8px 8px 0px 0px;
            background: $blue;
          }

          .selector-underline.Buy-active {
            transition: 0.3s right;
          }

          .selector-underline.Sell-active {
            transition: 0.3s right;
            right: calc(50% - 34px);
          }

          .selector-underline.Trade-active {
            transition: 0.3s right;
            right: calc(100% - 68px);
          }
        }

        .select-row span {
          opacity: 0.5;
        }

        .select-row span.active {
          opacity: 1;
        }
      }

      .divider {
        display: block;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid $border;
        margin-top: 20px;
      }
    }

    .head-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filter-dropdown {
        position: absolute;
        top: 66px;
        left: 0;
        background: $white;
        padding: 10px;
        width: 354px;
        border-radius: 20px;
        border: 1px solid $border;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 8px;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));

        @include pmx-pre-lg {
          filter: unset;
        }

        li {
          display: flex;
          align-items: center;
          border-radius: 8px;
          box-sizing: border-box;
          width: 163px;
          height: 35px;
          justify-content: center;
          font-weight: 700;
          background: $outlinedBg;
          padding: 8px 16px;
          font-size: 12px;
          transition: 0.2s background;
          cursor: pointer;
          white-space: nowrap;
        }

        li:hover {
          background: $extraLightBlue;
          transition: 0.2s background;
        }

        @include pmx-pre-lg {
          width: 100%;
          height: 300px;
          overflow-y: scroll;
          z-index: 20;
          top: 46px;

          li {
            width: 100%;
          }
        }
      }

      h3 {
        font-size: 20px;
        font-weight: 700;
      }

      .search {
        display: flex;
        justify-content: end;
        align-items: center;

        .pmx-input {
          height: 48px;
          padding: 12px 16px;

          input {
            width: 335px;
            max-width: unset;
          }
        }

        .search-buttons {
          display: flex;
          .pmx-button {
            margin-right: 16px;

            button {
              width: 96px;
              height: 48px;
              padding: 14px 16px;
              font-weight: 700;
              border-radius: 16px;

              span {
                font-size: 14px;
                white-space: nowrap;
              }

              @include pmx-pre-lg {
                height: 38px;
              }
            }
          }
        }

        @include pmx-pre-lg {
          align-items: unset;
        }
      }

      .order-state-button {
        display: flex;
        margin-left: unset;
        width: fit-content;
        button {
          height: 48px;

          span {
            color: black;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-orders-container {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;

    .pmx-orders {
      padding: 16px;
      width: 100%;

      .head-row {
        flex-wrap: wrap;

        .filter-row {
          width: 50%;
          margin-top: 0;

          .pmx-button {
            button {
              height: 40px;
            }
          }

          .filter-dropdown {
            width: 100%;
            height: 300px;
            overflow-y: scroll;
            z-index: 20;
            top: 46px;
            filter: unset;
            padding: 8px;

            li {
              width: 100%;
            }
          }
        }

        h3 {
          width: 50%;
        }

        .search {
          width: 100%;
          margin-top: 16px;
          box-sizing: border-box;
          justify-content: space-between;

          .pmx-input {
            height: 38px;
            padding: 10px;
            width: 60%;
            margin: 0;

            svg {
              width: 18px;
              height: 18px;
            }

            @include pmx-pre-lg {
              width: 50%;
            }
          }

          .pmx-button {
            margin-right: 16px;
            margin-left: 0;
            width: max-content;

            button {
              height: 40px;
            }
          }

          .search-buttons {
            margin-top: 5%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            @include pmx-pre-lg {
              margin-top: unset;
            }
          }
        }
      }

      .body {
        .head-row {
          padding: 0;

          h3 {
            font-size: 14px;
          }

          a {
            font-size: 14px;
          }
        }

        .tabs {
          margin-top: 32px;
          width: 100%;

          .select-row {
            width: 100%;
            justify-content: space-between;

            span {
              font-size: 14px;
              margin-right: 0;
            }

            .selector-underline {
              width: 57px;
              height: 5px;
            }

            .selector-underline.buy-active {
              right: 0;
            }

            .selector-underline.sell-active {
              right: calc(50% - 28.5px);
            }

            .selector-underline.exchange-active {
              right: calc(100% - 57px);
            }
          }

          .divider {
            position: relative;
          }
        }

        .orders {
          margin-top: 16px;
          grid-template-columns: auto;
          grid-template-rows: auto;
          row-gap: 16px;
          column-gap: 16px;

          .empty-box {
            grid-column: 1;
          }
        }
      }

      > .divider {
        margin-top: 24px;
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-order-card .pmx-enter-phone {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 401px;

  @include pmx-pre-lg {
    min-height: 264px;
  }

  > h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;

    @include pmx-pre-lg {
      font-size: 14px;
    }
  }

  .description {
    font-size: 16px;
    color: $greyText;
    margin-top: 8px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  .pmx-ordercard-buttons {
    padding-top: 24px;
  }

  .error-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;

    svg {
      path {
        fill: $thin-red;
      }
    }

    span {
      color: $thin-red;
    }
  }

  .pmx-ordercard-buttons {
    margin-top: auto;
    margin-bottom: 24px;
  }

  .ant-input-affix-wrapper {
    margin-top: 24px;

    .ant-input-suffix {


      .select-country {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;

          @include pmx-pre-lg {
            width: 32px;
            height: 32px;
          }
        }

        .code {
          font-size: 24px;
          font-weight: 700;
          margin-left: 16px;

          @include pmx-pre-lg {
            font-size: 14px;
          }
        }

      }

      .vertical-divider {
        display: block;
        height: 100%;
        width: 1px;
        border-right: 1px solid $lightGreyBorder;
        margin: 0 24px;
        max-height: 43px;

        @include pmx-pre-lg {
          margin: 0 8px;
        }
      }

    }


  }


}

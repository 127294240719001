@import "core/styles/refrences/index";

.pmx-security {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 32px;

  .upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 32px;
    height: 515px;

    @include pmx-pre-lg {
      max-height: unset;
    }

    .pmx-card {
      padding: 24px;
      height: 100%;
    }
  }

  @include pmx-pre-lg {
    padding: 24px 0 140px;

    .upper {
      padding: 0 24px;
      flex-direction: column;
      height: auto;

      .pmx-card {
        padding: 24px 16px 16px;
        margin-top: 24px;

        .head {
          h4 {
            font-size: 16px;
          }
        }

        .body {
          .pmx-button {
            button {
              height: 48px;

              span {
                font-size: 12px;
              }
            }
          }
        }
      }

      .pmx-card:first-child {
        margin-top: 0;
      }

      .password-form-container {
        width: 100%;
        margin: auto !important;

        .password-form {
          margin-top: 6px;

          .body {
            form {
              margin-top: 24px;

              .row {
                margin-top: 24px;
              }

              .row:first-child {
                margin-top: 0;
              }

              .pmx-input {
                height: 48px;
              }
            }
          }
        }
      }

      .twofa-form-container {
        width: 100%;
      }
    }
  }
}

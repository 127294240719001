@import "../../core/styles/refrences/index";

.pmx-input-material {
  display: flex;
  margin: 0 0;
  position: relative;
  box-sizing: border-box;

  label {
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: -40px;
    right: 0;
    color: $blue;
  }

  ::placeholder {
    color: $lightPurple;
    opacity: 1; /* Firefox */
  }

  input {
    background-color: $outlinedBg;
  }

  input {
    width: 392px;
    font-size: 16px;
    outline: none;
    padding: 22px 24px 21px;
    border: 0;
    background: none;
    border-radius: 20px;
    background: $outlinedBg;
  }

  .country {
    margin-right: 16px;
    display: flex;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      margin-right: 16px;
    }

    span {
      direction: ltr;
      font-size: 32px;
      font-weight: 700;
      color: $blue;
    }
  }

  .adornments {
    position: absolute;
    top: calc(50% - 15px);
    left: 15px;
    display: flex;
    align-items: center;
    height: 30px;

    .password-toggle {
      display: flex;
      margin: auto;
      cursor: pointer;
    }

    img,
    svg,
    .anticon {
      max-width: 30px;
      max-height: 30px;
    }

    svg {
      path {
        fill: $lightPurple;
      }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-referral {
  .rules {
    padding: 24px;
    width: 100%;
    margin: auto;

    .text {

      p {
        font-size: 16px;
        font-weight: 500;
        margin-top: 16px;

        span {
          font-size: 16px;
          font-weight: 700;
          margin-left: 8px;
        }

        @include pmx-pre-lg {
          font-size: 14px;
          span {
            font-size: 14px;
          }
        }
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
      }
    }
  }

  .rules .body {
    .pmx-button {
      button {
        height: 64px;
        width: 100%;
      }
    }
  }

  @include pmx-pre-lg {
    margin: auto 0;
    width: 100%;
    padding: 24px;
  }
}

@include pmx-pre-lg {
  .pmx-referral {

    .rules {
      .body {
        .pmx-button {
          margin-top: 32px;

          button {
            height: 48px;
          }
        }
      }
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-telegram-bot {
  display: flex;
  width: 75%;
  margin-inline: auto;
  margin-top: 120px;
  justify-content: space-between;
  align-items: center;

  @include pmx-pre-lg {
    width: 90%;
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    flex-direction: column;
    margin-top: 40px;
  }

  .description {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;

    @include pmx-pre-lg {
      margin-top: 16px;
      width: 90%;
    }

    h3 {
      display: flex;
      width: fit-content;
      font-size: 32px;
      font-weight: 700;
      text-align: right;

      @include pmx-pre-lg {
        font-size: 20px;
        justify-content: center;
        width: unset;
      }
    }

    p {
      display: flex;
      font-size: 18px;
      font-weight: 500;
      color: #777e90;

      @include pmx-pre-lg {
        text-align: center;
        justify-content: center;
        font-size: 12px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: unset;

      @include pmx-pre-lg {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      li {
        display: flex;
        gap: 12px;
        font-size: 14px;
        font-weight: 500;
        align-items: center;

        @include pmx-pre-lg {
          font-size: 10px;
          font-weight: 700;
        }

        svg {
          padding: 8px;
          border-radius: 50%;
          background: linear-gradient(180deg, #e6fff4 0%, #ffffff 100%);
        }
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: white;
      background: #3772ff;
      border-radius: 16px;
      padding: 4px 16px;
      font-weight: 700;
      font-size: 16px;
      width: 25%;
      height: 48px;
      white-space: nowrap;
      cursor: pointer;

      @include pmx-pre-lg {
        width: 90%;
        height: 40px;
        font-size: 14px;
      }
    }

    .blue-circle-icon {
      position: absolute;
      top: -10%;
      left: 10%;

      @include pmx-pre-lg {
        display: none;
      }
    }

    .blue-star-icon {
      position: absolute;
      top: 20%;
      right: -20%;

      @include pmx-pre-lg {
        display: none;
      }
    }

    .yellow-circle-icon {
      position: absolute;
      bottom: -10%;
      right: 20%;

      @include pmx-pre-lg {
        display: none;
      }
    }
  }

  .banner-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    max-width: 560px;
    max-height: 560px;
    width: 40%;
    // padding: 48px;
    border-radius: 160px;
    box-shadow: 0px 0px 100px 0px #0000000D;
    box-sizing: border-box;
    padding: 64px;
  }

  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    background: linear-gradient(180deg, #3772ff 0%, #ffffff 100%);
    border-radius: 80px;


    aspect-ratio: 1/1;

    @include pmx-pre-lg {
      width: 80%;
      margin: 48px 0;
    }

    img {
      width: fit-content;
      height: 50%;
      max-width: 211px;
      max-height: 240px;
      animation: move-animate 3.5s infinite ease-in-out;
    }

    .security-icon,
    .chat-icon,
    .heart-icon,
    .analytics-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      position: absolute;
      border-radius: 50%;

      svg {
        @include pmx-pre-lg {
          width: 20px;
          height: 20px;
        }
      }

      @include pmx-pre-lg {
        width: 48px;
        height: 48px;
      }
    }

    .security-icon {
      background: linear-gradient(
                      180deg,
                      #e6fff4 0%,
                      rgba(255, 255, 255, 0) 100%
      );
      top: -25%;

      @include pmx-pre-lg {
        top: -24px;
      }
    }

    .chat-icon {
      background: linear-gradient(
                      180deg,
                      #fffbe6 0%,
                      rgba(255, 255, 255, 0) 100%
      );
      right: -25%;

      @include pmx-pre-lg {
        right: -24px;
      }
    }

    .heart-icon {
      background: linear-gradient(
                      180deg,
                      #ffe6e6 0%,
                      rgba(255, 255, 255, 0) 100%
      );
      bottom: -25%;

      @include pmx-pre-lg {
        bottom: -24px;
      }
    }

    .analytics-icon {
      background: linear-gradient(
                      180deg,
                      #f2e6ff 0%,
                      rgba(255, 255, 255, 0) 100%
      );

      @include pmx-lg {
        display: none;
      }

      @include pmx-pre-lg {
        left: -24px;
      }
    }

    .golden-star-icon {
      position: absolute;
      bottom: -10%;
      left: -30%;

      @include pmx-pre-lg {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 4px 16px;
      height: 48px;
      position: absolute;
      background: linear-gradient(
                      180deg,
                      #e3eafa -18.75%,
                      rgba(255, 255, 255, 0) 100%
      );
      border-radius: 16px;
      color: black;
      font-size: 16px;
      font-weight: 700;

      @include pmx-pre-lg {
        display: none;
      }

      &:first-of-type {
        top: 10%;
        right: -15%;
      }

      &:last-of-type {
        bottom: 10%;
        left: -15%;
      }
    }
  }
}

@keyframes move-animate {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-30px);
  }

  100% {
      transform: translateY(0);
  }
}
@import "../../../../../core/styles/refrences/index.scss";

.pmx-download-application {
  display: flex;
  width: 75%;
  height: auto;
  margin-inline: auto;
  border: 1px solid #f0f0f0;
  border-radius: 16px;
  margin-top: 80px;
  //background: linear-gradient(0deg, #ffffff, #e6e6e633);
  background: linear-gradient(360deg, rgba(229, 242, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);


  @include pmx-pre-lg {
    width: 90%;
    flex-direction: column-reverse;
    margin-top: 40px;
  }

  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: auto;
    position: relative;

    @include pmx-pre-lg {
      width: 100%;
    }

    .mobile-bg {
      width: 100%;
      max-width: 334px;
      height: auto;
      position: absolute;

      @include pmx-pre-lg {
        position: relative;
        max-width: unset;
        margin-top: 80px;
      }
    }

    .mobile-phone {
      width: 272px;
      height: 110%;
      bottom: 0px;
      position: absolute;

      @include pmx-pre-lg {
        height: auto;
      }
    }

    .qr-code {
      display: flex;
      flex-direction: column;
      width: 50%;
      max-width: 116px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      position: absolute;
      background-color: white;
      padding: 16px 8px 16px 8px;

      @include pmx-pre-lg {
        bottom: 10%;
      }

      span {
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        margin-top: 8px;
      }
    }
  }

  .content {
    display: flex;
    gap: 32px;
    padding: 32px;
    align-items: center;

    @include pmx-pre-lg {
      flex-direction: column;
      padding: 16px;
    }

    .col1 {
      display: flex;
      flex-direction: column;

      @include pmx-pre-lg {
        align-items: center;
      }

      h4 {
        font-size: 32px;

        @include pmx-pre-lg {
          font-size: 20px;
        }
      }

      p {
        margin-top: 32px;
        font-size: 14px;
        color: #777e90;

        @include pmx-pre-lg {
          margin-top: 12px;
          text-align: center;
          font-size: 12px;
        }
      }

      .ant-input-affix-wrapper {
        @include pmx-pre-lg {
          display: none;
        }

        margin-top: 32px;
        border: 1px solid #f0f0f0;
        border-radius: 32px;
        height: 48px;

        .ant-input {
          &::placeholder {
            font-size: 14px;
            font-weight: 700;
          }
        }

        .ant-input-suffix {
          margin-right: 8px;
        }
      }
    }

    .col2 {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      h5 {
        @include pmx-pre-lg {
          font-size: 14px;
          font-weight: 700;
        }
      }

      .col {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include pmx-pre-lg {
          width: 100%;
          align-items: center;
          text-align: center;
          gap: 8px;
        }

        &:last-of-type {
          margin-top: 32px;

          @include pmx-pre-lg {
            margin-top: 16px;
          }
        }

        > div {
          display: flex;
          gap: 16px;

          a {
            display: flex;
            align-items: center;
            gap: 8px;
            background: $white;
            border: 1px solid #f0f0f0;
            border-radius: 8px;
            padding: 8px;

            span {
              color: $dark-grey;
              font-size: 14px;
              font-weight: 700;
              white-space: nowrap;
              opacity: .5;

              @include pmx-pre-lg {
                font-size: 12px;
              }
            }

          }
        }
      }
    }
  }
}

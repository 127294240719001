@import "../../../core/styles/refrences/index.scss";

.pmx-about {
  > .ant-divider {
    min-width: unset;
    width: 90%;
    margin: 48px auto;

    @include pmx-pre-lg {
      margin: 16px auto;
      display: none;
    }

  }
}

@import '../../../../core/styles/refrences/index.scss';

.pmx-hints {
  display: flex;
  gap: 16px;
  width: 75%;
  margin-inline: auto;
  margin-top: 64px;

  @include pmx-pre-lg {
    width: 90%;
    margin-top: 48px;
    flex-wrap: wrap;
  }

  .hint {
    width: 100%;
    padding: 16px;
    border: 1px solid #f0f0f0;
    border-radius: 16px;
    display: flex;
    flex-direction: column;

    @include pmx-pre-lg {
      width: calc(50% - 42px);

      &:last-of-type {
        width: 100%;

        h4 {
          text-align: center;
        }

        p {
          text-align: center;
        }
      }
    }

    h4 {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 700;

      @include pmx-pre-lg {
        margin-top: 16px;
        font-size: 14px;
      }
    }

    p {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 500;
      color: #777E90;

      @include pmx-pre-lg {
        margin-top: 12px;
        font-size: 12px;
      }
    }

    .timer-icon, .identity-icon, .warning-icon, .card-icon, .rules-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      border-radius: 50%;

      @include pmx-pre-lg {
        width: 48px;
        height: 48px;
      }
    }

    .timer-icon {
      background-color: #F4E8FF;
    }

    .identity-icon {
      background-color: #E5F2FF;
    }

    .warning-icon {
      background-color: $dark-yellow;
    }

    .card-icon {
      background-color: #F5DCDC;
    }

    .rules-icon {
      background-color: #DCF5EA;

      @include pmx-pre-lg {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}
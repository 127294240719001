@import "../../../../../core/styles/refrences/index.scss";

.pmx-partners {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-inline: auto;

  @include pmx-pre-lg {
    width: 90%;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    text-align: right;

    @include pmx-pre-lg {
      font-size: 20px;
    }
  }

  .partners {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 32px;
    margin-top: 32px;
    
    @include pmx-pre-lg {
      flex-wrap: wrap;
    }


    .partner {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F8F8F9;
        border: 1px solid #E6E6E6;
        border-radius: 16px;
        height: 120px;
        width: 100%;

        @include pmx-pre-lg {
          flex: 0.5;
          height: 80px;

          &:last-child {
            flex: 1;
          }
        }
    }
  }


}

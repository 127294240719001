@import "core/styles/refrences/index";

.invite-box {
  display: flex;
  flex-direction: column;
  width: 25%;
  background: $white;
  border: 1px solid $border;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
  height: 100%;
  max-height: 257px;

  .ant-spin-nested-loading,
  .ant-spin,
  .ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include pmx-pre-lg {
    width: 100%;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .pmx-button {
    margin-top: 35px;
    button {
      border-radius: 40px;
      background: rgba(55, 114, 255, 0.05);
      max-width: unset;
      height: 48px;

      span {
        font-family: YekanBakh-En;
      }
    }
  }

  .list {
    margin-top: 37px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-divider {
      margin: unset;
    }

    .row {
      display: flex;
      justify-content: space-between;

      p {
        color: #777e90;
        text-align: right;
        font-size: 14px;
        font-weight: 600;
      }

      .value {
        font-size: 20px;
        color: $blue;
        font-size: 14px;
        font-weight: 600;

        span {
          color: $blue;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

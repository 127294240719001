@import "../../../../../core/styles/refrences/index.scss";

.pmx-landing-about {
  position: relative;
  display: flex;
  background: #526bfc;
  width: 100%;
  height: 400px;

  .pmx-navbar-home {
    margin-top: 32px;
  }

  .pmx-mobile-navbar-home {
    margin-top: 32px;
  }

  #about-banner {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

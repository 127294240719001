@import '../../../core/styles/refrences/index.scss';

.pmx-terms {
    .pmx-main-conditions {
        width: 75%;
        margin-top: 96px;
        margin-inline: auto;
        font-weight: 700;
        font-size: 16px;

        @include pmx-pre-lg {
            width: 90%;
            margin-top: 48px;
        }

    }
}
@import "../../../../../../../core/styles/refrences/index.scss";

.pmx-tracking-order-stage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  height: auto;
  position: relative;
  height: auto;

  .tracking-order {
    padding: 24px 0px 0px;
    display: flex;
    flex-direction: column;

    .tracking-input {
      font-family: YekanBakh-En!important;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;

      @include pmx-pre-lg {
        font-size: 16px;
      }
    }

    p {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 500;
      color: #909090;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }

    .pmx-input {
      max-width: unset;
      width: 100%;
      margin-top: 16px;
      height: 64px;

      input {
        font-family: YekanBakh-En !important;        
      }
    }

    .buttons {
      margin-top: 48px;
    }
  }

  .payment-mask {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: 0;
  }

  .transaction-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 32px;
    z-index: 1;

    img {
      width: 64px;
      height: 64px;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-top: 16px;

      @include pmx-pre-lg {
        font-size: 14px;
      }
    }

    p {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 500;
      color: #909090;
      text-align: center;

      @include pmx-pre-lg {
        font-size: 12px;
      }
    }
  }

  .transaction-info {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #f9fcff;
    padding: 24px;
    z-index: 1;
    margin-top: 16px;
    gap: 16px;

    .transaction-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;

      span {
        font-size: 14px;
        font-weight: 700;

        @include pmx-pre-lg {
          font-size: 12px;
        }

        &:first-child {
          opacity: 0.5;
        }

        &:last-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          flex-direction: row-reverse;

          &.copiable {
            color: #3772FF;
          }
        }
      }

      a {
        display: flex;
        text-decoration: none;
      }

      .pmx-badge {
        span {
          opacity: unset;
        }
      }

      #tracking-code,
      #tx-id,
      #voucher,
      #voucher-activation,
      #batch-number {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #3772ff;
        font-family: YekanBakh-En !important;
        flex-direction: row-reverse;

        svg {
          cursor: pointer;
          min-width: 32px;
          min-height: 32px;
        }
      }

      #tx-id {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 75%;

      justify-content: flex-end;
      }
    }

    .photo-alert {
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: #bd58581a;
      border: 1px solid #bd5858;
      border-radius: 8px;
      padding: 16px;
      z-index: 1;

      span {
        font-size: 16px;
        font-weight: 700;
        color: #bd5858;

        @include pmx-pre-lg {
          font-size: 12px;
        }
      }

      svg {
        transform: translateY(-1px);
      }
    }
  }

  .transaction-image-upload {
    border-radius: 10px;
    border: 1px dashed #d9e5ff;
    background: #3772ff1a;
    height: 160px;
    margin-top: 24px;
    z-index: 1;

    .ant-upload-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  #retry {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    color: #1552f0;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    z-index: 1;
  }

  .transaction-image-guide {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    height: 64px;
    border: 1px solid #f0f0f0;
    align-items: center;
    color: #3772ff;
    font-size: 16px;
    font-weight: 700;
    gap: 4px;
    margin-top: 16px;
    cursor: pointer;
    z-index: 1;

    svg {
      transform: translateY(-1px);
    }
  }

  .transaction-timer {
    background-color: #f8f8f9;
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;
    z-index: 1;

    h5 {
      text-align: center;
      font-weight: 700;
      font-size: 20px;
    }

    .ant-statistic {
      margin-top: 8px;
      text-align: center;
      opacity: 0.5;

      .ant-statistic-content-value {
        font-size: 32px;
      }
    }
  }

  .pmx-button {
    z-index: 1;
    margin-top: 24px;
    button {
      max-width: unset;
    }
  }

  .buttons {
    width: 100%;
    margin: 24px 0 24px;
    display: flex;
    gap: 24px;
    z-index: 1;

    .pmx-button {
      width: auto;

      button {
        height: 64px;
        max-width: unset;

        @include pmx-pre-lg {
          height: 48px;
        }
      }

      button.ordercard_outlined {
        justify-content: center;

        span {
          color: $black;
          font-weight: 700;
        }
      }
    }

    .pmx-button:first-child {
      flex-grow: 5;
    }

    .pmx-button:last-child {
      flex-grow: 1;
    }
  }

  .pmx-selfie-guide {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    .selfie-guide-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 24px;
        font-weight: 700;
      }

      svg {
        cursor: pointer;
      }
    }

    img {
      display: flex;
      height: 311px;
      margin-inline: auto;
      border-radius: 8px;
      margin-top: 32px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;
    }
  }

  .pmx-authentication-result {
    display: flex;
    flex-direction: column;
    padding-top: 24px;

    .payment-mask {
      position: absolute;
      width: 100%;
      top: 0px;
      right: 0px;
      z-index: 0;
    }

    svg {
      z-index: 1;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 32px;
      z-index: 1;
    }

    p {
      color: #909090;
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;
      z-index: 1;
    }

    .buttons {
      width: 100%;
      margin: 24px 0 24px;
      display: flex;
      gap: 24px;
      z-index: 1;

      .pmx-button {
        width: auto;

        button {
          height: 64px;
          max-width: unset;

          @include pmx-pre-lg {
            height: 48px;
          }

          svg {
            path {
              stroke: white;
            }
          }
        }

        button.ordercard_outlined {
          justify-content: center;

          span {
            color: $black;
            font-weight: 700;
          }
        }
      }

      .pmx-button:first-child {
        flex-grow: 5;
      }

      .pmx-button:last-child {
        flex-grow: 1;
      }
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-path {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 64px auto;

  @include pmx-pre-lg {
    width: 90%;
    margin: 32px auto;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    text-align: right;

    @include pmx-pre-lg {
      font-size: 20px;
    }
}

p {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    
    @include pmx-pre-lg {
      font-size: 12px;
      margin-top: 16px;
    }
}
}

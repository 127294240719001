@import "../../../../core/styles/refrences/index.scss";

.pmx-terms-landing {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: radial-gradient(
    100% 100% at 50% 100%,
    #739dff 3.12%,
    #3772ff 100%
  );
  height: 480px;

  @include pmx-pre-lg {
    height: 400px;
  }

  .pmx-mobile-navbar-home {
    margin-top: 32px;
  }

  .pmx-navbar-home {
    margin-top: 32px;
    z-index: 1;
  }

  #banner {
    filter: opacity(0.5);
    position: absolute;
    z-index: 0;
    height: 100%;
    margin-inline: auto;
    width: 100%;

    @include pmx-pre-lg {
        width: 100%;
    }
  }

  .hint-box {
    display: flex;
    flex-direction: column;
    font-size: 32px;
    font-weight: 700;
    color: white;
    width: 30%;
    margin: auto;
    z-index: 1;
    transform: translateY(-32px);

    @include pmx-pre-lg {
        width: 100%;
    }
    
    h2 {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        
        @include pmx-pre-lg {
            font-size: 20px;
        }
    }
    
    p {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        white-space: nowrap;
        margin-top: 24px;

        @include pmx-pre-lg {
            font-size: 12px;
        }
    }
  }
}

@import "core/styles/refrences/index";

.pmx-security .table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  .activities {
    margin-right: 32px;
  }

  .logs {
    width: 100%;

    h3 {
      font-size: 20px;
      font-weight: 700;
    }

    .pmx-card {
      margin-top: 16px;
      padding: 0;

      .head {
        padding: 24px;
        font-size: 20px;
        border-bottom: 1px solid #f0f0f0;
      }


      * {
        box-sizing: border-box;
      }
    }

    table {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-items: start;
      align-items: space-between;

      .divider {
        display: inline-flex;
        width: 100%;
        height: 1px;
        border-top: 1px solid $border;
      }

      thead {
        box-sizing: border-box;
        padding: 24px 24px 16px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        th {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-weight: 700;
          width: calc(100% / 4);

          @include pmx-size(font-size, 13px, 12px)
        }

        // th:first-child {
        //   justify-content: center;
        // }

        // th:last-child {
        //   justify-content: end;
        // }
      }

      tbody {
        display: flex;
        flex-direction: column;
        width: 100%;

        tr {
          display: flex;
          justify-content: space-evenly;
          padding: 8px;

          td {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% / 4);

            span {
              font-size: 13px;
              font-weight: 700;

              @include pmx-size(font-size, 13px, 12px);
            }

            img {
              width: 21px;
              height: 21px;
            }
          }

          // td:first-child {
          //   justify-content: start;
          // }

          // td:last-child {
          //   justify-content: end;
          // }
        }
      }
    }
  }
}

@include pmx-pre-lg {
  .pmx-security .table {
    h3 {
      font-size: 16px;
      padding-right: 24px;
    }


    .pmx-card {
      border-radius: 0;
      border: 0px;
      border-bottom: .5px solid $border;

      table {

        thead {
          padding: 24px 24px 16px;

        }

        tbody {

          tr {
            padding: 16px 21px 16px 24px;


          }
        }

        .divider {
          border-width: .5px;
        }
      }
    }
  }
}

@import "../../../core/styles/refrences/index";

.sidebar-item {
  width: 100%;
  box-sizing: border-box;
  margin-inline: auto;

  &.compact {
    a {
      .content {
        justify-content: center;
        padding: 16px;
      }
    }
  }

  &:first-child {
    a {
      margin-top: 0;
    }
  }

  &.active {
    a {
      background: #3772ff;
      color: $white;

      .content {
        p {
          color: white
        }
      }
    }

    svg {
      path {
        stroke: white;
      }

      g {
        path {
          stroke: white;
        }
        rect {
          stroke: white;
        }
      }
    }
  }

  // &.filled {
  //   background: $primary;
  //   color: $white;

  //   svg {
  //     rect {
  //       fill: $white;
  //     }

  //     path {
  //       fill: $white;
  //     }
  //   }
  // }

  a {
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    color: $blue;
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
    width: 100%;
    position: relative;

    svg {
      &.expanded {
        transform: rotate(90deg);
      }
    }

    > svg {
      position: absolute;
      left: 16px;
      width: 32px;
      height: 32px;
      top: 50%;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 24px;
      gap: 24px;

      p {
        color: #777e90;
        font-size: 500;
        text-align: right;
        font-size: 18px;
        white-space: nowrap;
        // padding-right: 24px;
      }

      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  ul.item-body {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 10px;

    li {
      padding: 10px;
      width: 100%;
      position: relative;
      display: flex;
      color: $blue;
      font-size: 14px;

      &.active {
        background-color: $blue;
        color: $white;
        width: 100%;
        margin: 0;
        border-radius: 20px;
      }
    }
  }
}

@import "../../../../../core/styles/refrences/index.scss";

.pmx-landing-home {
  background: radial-gradient(circle at 50% 100%, #739dff 3.12%, #3772ff 100%);
  width: 100%;
  height: auto;
  padding: 32px 0 40px;
  position: relative;

  @include pmx-pre-lg {
    background: radial-gradient( 100% 100% at 50% 100%, #739dff 3.12%, #3772ff 100% );
  }

  .instruction {
    display: flex;
    width: fit-content;
    margin-top: 40px;
    gap: 16px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    margin-inline: auto;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: transform 0.5s ease-in-out;
    
    svg {
      transition: transform 0.5s ease-in-out;
      animation: pulse 2s infinite;
    }
    
    &:hover {
      animation: pulse 2s infinite;
      transition: transform 0.5s ease-in-out;
    }
  }

  #landing-mask {
    position: absolute;
    width: 100%;
    margin-inline: auto;
    transform: translateY(-86%);
    z-index: 0;
    user-select: none;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@import "../../../../../../../../core/styles/refrences/index.scss";

.pmx-remove-iban-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include pmx-pre-lg {
    top: 40%;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;

    @include pmx-pre-lg {
      font-size: 16px;
    }
  }

  p {
    margin-top: 8px;
    color: $greyText;
    font-size: 16px;

    @include pmx-pre-lg {
      font-size: 12px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 16px;
    border-radius: 8px;
    background: $extraLightBlue;
    margin-top: 16px;

    @include pmx-pre-lg {
      height: 48px;
      padding: 0 8px;
    }

    span {
      &:first-child {
        font-size: 20px;

        @include pmx-pre-lg {
          font-size: 16px;
        }
      }

      &:last-child {
        font-size: 16px;

        @include pmx-pre-lg {
          font-size: 12px;
        }
      }
    }
  }

  section {
    display: flex;
    margin-top: 32px;
    gap: 16px;

    @include pmx-pre-lg {
      margin-top: 16px;
    }

    .pmx-button {
      max-width: unset;
      width: 100%;
      background: initial;
      padding: unset;
      margin: unset;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        max-width: unset;

        @include pmx-pre-lg {
          height: 48px;
        }
      }
    }
  }
}
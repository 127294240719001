@import "core/styles/refrences/index";

.ReactModal__Overlay {
  background: rgba(18, 18, 18, 0.05) !important;
  backdrop-filter: blur(2.5px) !important;
}

.pmx-modal {
  min-width: 436px;
  min-height: 179px;
  background: $white;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
  position: absolute;
  left: calc(50% - 268px);
  top: calc(50% - 90px);
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;

  @include pmx-size(left, 35%, 5%);

  &:focus-visible {
    outline: none;
  }

  .modal-head {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 16px;

    justify-content: space-between;

    > .close {
      width: 20px;
      height: 20px;
      cursor: pointer;

      path {
        fill: $black;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: 700;
    }
  }
}

@include pmx-pre-lg {
  .pmx-modal {
    min-width: unset;
    min-height: unset;
    width: 90%;
    margin: 0;
    //top: 40%;
    //left: 0;
  }
}
